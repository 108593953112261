
/*
 * VNCcontact+ : A new level of contact management
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { OnDestroy, Injectable } from "@angular/core";
import { ContactService } from "src/app/common/service/contact-service";
import { debounceTime, distinctUntilChanged, take, filter, takeUntil, mergeMap, catchError } from "rxjs/operators";
import { Contact } from "src/app/common/models/contact.model";
import { ContactUtils } from "src/app/common/utils/contacts-utils";
import { LoadContacts, ContactRootState, LoadContactsFail, LoadContactsSuccess, getIsContactsNextPageLoading, getIsContactssNextPageLoaded, getContactsCurrentPageOffset, getIsMoreContacts, NextLoadContacts, NextLoadContactsSuccess, NextLoadContactsFail, CreateContactSuccess, RemoveMultipleContactSuccess, UpdateContact, StartLoading, UpdateContactSuccess, StopLoading, UpdateContactFail, getContactTags, getContactFolders, UpdateMultipleContactSuccess, getContacts, getTrashContactsCurrentPageOffset, getIsMoreTrashContacts, getGroupContactsCurrentPageOffset, getIsMoreGroupContacts, getListContacts, getFrequentlyContactsCurrentPageOffset, getIsMoreFrequentlyContacts } from "../store";
import { Store } from "@ngrx/store";
import { ToastService } from "src/app/common/service/tost.service";
import { Broadcaster } from "src/app/common/providers/broadcaster.service";
import { BroadcastKeys } from "src/app/common/enums/broadcast.enum";
import * as _ from "lodash";
import { forkJoin, Observable, BehaviorSubject, Subject, of, from } from "rxjs";
import { AuthUser } from "src/app/common/models";
import { LocalStorageService } from "src/app/common/providers/storage.service";
import { RootState, getRouteType, getRouteId, getFavoriteGroup, getFederatedApps, getOnlineStatus, getSearchQuery, getSearchInLists, getSearchInTags, getLoggedInUserContact, getStatistics, getContactsCount } from "src/app/reducers";
import { SetUserProfile, SetFavoriteGroup, SetLoggedInUserContact, SetStatistics, SetSaveSearch, SetRoutingType, SetRouteId, SetQueryFilters, SetContactsCount } from "src/app/actions/app";
import { AppConstants } from "src/app/common/utils/app-constants";
import { TranslateService } from "@ngx-translate/core";
import { ConfigService } from "src/app/common/providers/config.service";
import { CommonUtil } from "src/app/common/utils/common.utils";
import { environment } from "src/environments/environment";
import { ActivatedRoute, Router } from "@angular/router";
import { ContactFolder } from "../models/create-folder.model";
import { LoadContactFolders, LoadContactFoldersSuccess, LoadContactFoldersFail, CreateContactFolderSuccess, CreateContactFolderFail, CreateContactFolder, UpdateContactFolderSuccess, UpdateContactFolderFail, UpdateContactFolder, DeleteContactFolder, DeleteContactFolderSuccess, DeleteContactFolderFail } from "../store/actions/contact-folder.action";
import { LoadContactTags, LoadContactTagsSuccess, LoadContactTagsFail } from "../store/actions/contact-tag.action";
import { FilesStorageService } from "src/app/common/service/files-storage.service";
import { ContactTag } from "../models/contact-tag.model";
import { SearchResponse } from "src/app/common/models/search-item";
import { MatDialog } from "@angular/material/dialog";
import { CreateActionGroupDialogComponent } from "../components/create-action-group-dialog/create-action-group-dialog.component";
import { AdvanceSearchDialogComponent } from "src/app/shared/components/advance-search-dialog/advance-search-dialog.component";
import { ElectronService } from "src/app/common/service/electron.service";
import { SaveSearchNameDialogComponent } from "src/app/shared/components/save-search-name-dialog/save-search-name-dialog.component";
import { ConfirmationDialogComponent } from "src/app/shared/components/confirmation-dialog/confirmation-dialog.component";
import { ConfirmDialogType } from "src/app/common/models/dialog.model";
import { LoadTrashContactsSuccess, LoadTrashContacts, LoadTrashContactsFail, NextLoadTrashContacts, NextLoadTrashContactsSuccess, NextLoadTrashContactsFail, RemoveMultipleTrashContactSuccess } from "../store/actions/trash-contacts.action";
import { LoadListContacts, LoadListContactsSuccess, LoadListContactsFail, RemoveMultipleListContactSuccess, UpdateMultipleListContactSuccess, CreateListContactSuccess, UpdateListContact, StartListLoading, UpdateListContactSuccess, StopListLoading, UpdateListContactFail, NextLoadListContactsSuccess } from "../store/actions/list-contacts.action";
import { LoadTagContacts, LoadTagContactsSuccess, LoadTagContactsFail, RemoveMultipleTagContactSuccess, CreateTagContactSuccess, UpdateTagContact, StartTagLoading, UpdateTagContactSuccess, StopTagLoading, UpdateTagContactFail, UpdateMultipleTagContactSuccess } from "../store/actions/tag-contacts.action";
import { LoadGroupContacts, LoadGroupContactsSuccess, LoadGroupContactsFail, NextLoadGroupContacts, NextLoadGroupContactsSuccess, NextLoadGroupContactsFail, RemoveMultipleGroupContactSuccess, UpdateGroupContact, StartGroupLoading, UpdateGroupContactSuccess, StopGroupLoading, UpdateGroupContactFail, CreateGroupContactSuccess } from "../store/actions/group-contacts.action";
import { LoadFrequntlyContacts, LoadFrequntlyContactsFail, LoadFrequntlyContactsSuccess, NextLoadFrequntlyContactsFail, NextLoadFrequntlyContactsSuccess, RemoveMultipleFrequntlyContactSuccess, StartFrequntlyLoading, StopFrequntlyLoading, UpdateFrequntlyContact, UpdateFrequntlyContactFail, UpdateFrequntlyContactSuccess } from "../store/actions/frequently-contacts.action";
import { DatabaseService } from "src/app/common/service/db/database.service";
import { IndexedDBService } from "src/app/common/service/db/indexedDB.service";
import { SnackBarNotificationComponent } from "src/app/shared/components/snackbar-notification/snackbar-notification.component";
import { MatSnackBar } from "@angular/material/snack-bar";

@Injectable()
export class ContactRepository implements OnDestroy {
  private limit: number = 500;
  private isAlive$ = new Subject<boolean>();
  private isOnline: boolean;
  private allContactIds: number[] = [];
  private allGlobalContactIds: number[] = [];
  private allTrashContactIds: number[] = [];
  private fetchedContacts = new BehaviorSubject<boolean>(false);
  private fetchedGlobalContacts = new BehaviorSubject<boolean>(false);
  private fetchedTrashContacts = new BehaviorSubject<boolean>(false);
  sortOrder : string = "asc";
  sortColumn :string = "first_name";
  private triggerProcessPendings$ = new BehaviorSubject<number>(0);
  private triggerAfterProcessPendings$ = new BehaviorSubject<number>(0);
  private isProcessingPending = false;
  contactDetails = {
    first_name: "",
    last_name: "",
    middle_name: "",
    job_title: "",
    is_company: "0",
    emails_attributes: [],
    phones_attributes: [],
    addresses_attributes: [],
    urls_attributes: [],
    im_accounts_attributes: [],
    company: "",
    firstLastCharacters: "",
    notes: "",
    custom_fields_attributes: [],
    events_attributes: [],
    contact_list_ids: [],
    tag_list: "",
    bgAvatarColor: "",
    favorite: false
};
trashSort: string = "asc";
tagRoute$ = new Subject<any>();
listRoute$ = new Subject<any>();
cachedAvatars = [];
updateAvatarCacheTrigger$ = new Subject<any>();

  constructor(
    private contactService: ContactService,
    private store: Store<ContactRootState | RootState>,
    private toastService: ToastService,
    private broadcaster: Broadcaster,
    private localStorage: LocalStorageService,
    private translate: TranslateService,
    private _snackBar: MatSnackBar,
    private configService: ConfigService,
    private router: Router,
    private fileStorageService: FilesStorageService,
    private matDialog: MatDialog,
    private electronService: ElectronService,
    private databaseService: DatabaseService,
    private activatedRoute: ActivatedRoute,
  ) {
    console.log("contactRepoConstructor");
    this.databaseService.dbReady.asObservable().pipe(filter(v => !!v), take(1)).subscribe(() => {
      this.databaseService.fetchContacts(false).subscribe(v => {
        let contacts: Contact[] = this.mapContacts(v);
        let isMoreContacts = true;
        let offset = contacts.length;
        this.store.dispatch(new LoadContactsSuccess({ currOffset: offset, isMoreContacts: isMoreContacts, contact: contacts }));
      });
    });
    this.store.select(getOnlineStatus).pipe(distinctUntilChanged()).subscribe(isOnline => {
      this.isOnline = isOnline;
      if (this.isOnline) {
        const nts = Date.now();
        console.log("onlineStatus -> processPendingTrigger ", nts, this.triggerProcessPendings$.value);
        if ((nts - this.triggerProcessPendings$.value) > 500) {
          this.triggerProcessPendings$.next(nts);
        }
      }
    });
    this.fetchedContacts.subscribe(v => {
      console.log("contact.repo fetchedContactIds: ", v, this.allContactIds);
      if (!!v) {
        this.fetchAggregatedGlobalContactIds(0);
      }
    });
    this.fetchedGlobalContacts.subscribe(v => {
      console.log("contact.repo fetchedGlobalContactIds: ", v, this.allGlobalContactIds);
      if (!!v) {
        this.fetchAggregatedTrashContactIds(0);
      }
    });
    this.fetchedTrashContacts.subscribe(v => {
      if (!!v) {
        console.log("contact.repo fetchedTrasContactIds: ", v, this.allTrashContactIds);
        this.databaseService.getAllContactIds().pipe(take(1)).subscribe(dbids => {
          // console.log("contact.repo fetchedTrasContactIds dbids: ", dbids);
          if (!!dbids && (dbids.length > 0)) {
            dbids.forEach(id => {
              if ((this.allContactIds.indexOf(id) === -1) && (this.allGlobalContactIds.indexOf(id) === -1) && (this.allTrashContactIds.indexOf(id) === -1)) {
                console.log("resyncIds need to delete id: ", id, this.allContactIds.indexOf(id), this.allGlobalContactIds.indexOf(id), this.allTrashContactIds.indexOf(id));
                this.databaseService.deleteContacts([id]).pipe(take(1)).subscribe(() => {
                  const nts = Date.now();
                  this.updateAvatarCacheTrigger$.next(nts);
                });
              } else {
                const nts = Date.now();
                this.updateAvatarCacheTrigger$.next(nts);
              }
            });
          } else {
            const nts = Date.now();
            this.updateAvatarCacheTrigger$.next(nts);
          }
        });
      }
    });
    this.triggerProcessPendings$.pipe(debounceTime(500)).subscribe(v => {
      if (v > 0) {
        this.processPendingOperations();
      }
    });
    this.triggerAfterProcessPendings$.pipe(debounceTime(2500)).subscribe(v => {
      if (v > 0) {
        this.getAllContact();
      }
    });
    this.updateAvatarCacheTrigger$.pipe(debounceTime(300)).subscribe(v => {
      console.log("updateAvatarCache trigger: ", v);
      this.updateAvatarCache();
    });
    this.broadcaster.on<any>("DBREADY").pipe(take(1)).subscribe(() => {
      this.databaseService.fetchAllAvatarFromDatabase().pipe(take(1)).subscribe(dbAvatars => {
        if (!!dbAvatars && (dbAvatars.length > 0)) {
          this.cachedAvatars = [...dbAvatars];
          // console.log("contactRepo reloadAvatarCache ", this.cachedAvatars);
          const bcdata = this.cachedAvatars.map(a => a.id);
          this.broadcaster.broadcast("CACHED_AVATARS_UPDATED", bcdata);
        }
      });
    });
  }

  ngOnDestroy() { }

  logout() {
    this.contactService.logout();
  }

  getAllContact(query?: string,sortOrder?): void {
    console.log("contact.repository getAllContact ", query);
    if (navigator.onLine) {

      setTimeout(() => {
        this.store.dispatch(new LoadContacts());
      }, 0);
      let offset = 0;
      this.contactService.getAllDirectoryContact(offset, this.limit, query,false,this.sortColumn,this.sortOrder).pipe(take(1)).subscribe(res => {
        if (!!res && res !== null && res.contacts) {
          let contacts: Contact[] = [];
          contacts = this.mapContacts(res.contacts);
          let isMoreContacts = false;
          offset = contacts.length;
          if (contacts.length === this.limit) {
            isMoreContacts = true;
          } else {
            offset = 0;
            isMoreContacts = false;
          }
          this.store.select(getContactsCount).pipe(take(1)).subscribe(v => {
            this.store.dispatch(new SetContactsCount({ ...v, contacts_count:  res.total_count}));
          });
          this.store.dispatch(new LoadContactsSuccess({ currOffset: offset, isMoreContacts: isMoreContacts, contact: contacts }));
        }
      }, error => {
        this.store.dispatch(new LoadContactsFail());
      });

      this.contactService.getAllDirectoryContact(0, 1, query, true,this.sortColumn,sortOrder).pipe(take(1)).subscribe(res => {
        this.store.select(getContactsCount).pipe(take(1)).subscribe(v => {
          console.log("[contact.repo] online getContactsCount:", v, res);
          this.store.dispatch(new SetContactsCount({ ...v, global_contacts_count:  res.total_count}));
        });
      });
    } else {
      console.log("contact.repository getAllContact offline");
      if (!query) {
        if (this.databaseService.dbReady.value) {
          this.databaseService.fetchContacts(false).subscribe(v => {
            console.log("[contact.repo] db.fetchContacts result:", v);
            let contacts: Contact[] = this.mapContacts(v);
            let isMoreContacts = false;
            let offset = contacts.length;
            this.store.dispatch(new LoadContactsSuccess({ currOffset: offset, isMoreContacts: isMoreContacts, contact: contacts }));
            this.store.select(getContactsCount).pipe(take(1)).subscribe(v => {
              console.log("[contact.repo] offline getContactsCount:", v);
              let stats = {
                contacts_count: contacts.length
              };
              this.store.dispatch(new SetContactsCount(stats));
            });
            this.databaseService.getContactCountInDatabase().subscribe(res => {
              console.log("[contact.repo] offline getContactCountInDatabase:", res);
              let stat = {
                contacts_count: res.count,
                global_contacts_count: res.global_contacts_count
              };
              this.store.dispatch(new SetContactsCount(stat));
            });
          });
        } else {
          setTimeout(() => {
            this.getAllContact(query);
          }, 400);
        }
      }
    }
  }

  getAllGlobalContacts(query?: string): void {
    console.log("contact.repository getAllGlobalContacts ", query,this.sortColumn,this.sortOrder);
    if (navigator.onLine) {
      setTimeout(() => {
        this.store.dispatch(new LoadContacts());
      }, 0);
      let offset = 0;
      this.contactService.getAllDirectoryContact(offset, this.limit, query, true, this.sortColumn,this.sortOrder).pipe(take(1)).subscribe(res => {
        if (!!res && res !== null && res.contacts) {
          let contacts: Contact[] = [];
          contacts = this.mapContacts(res.contacts);
          let isMoreContacts = false;
          offset = contacts.length;
          if (contacts.length === this.limit) {
            isMoreContacts = true;
          } else {
            offset = 0;
            isMoreContacts = false;
          }
          this.store.select(getContactsCount).pipe(take(1)).subscribe(v => {
            this.store.dispatch(new SetContactsCount({ ...v, global_contacts_count:  res.total_count}));
          });
          this.store.dispatch(new LoadContactsSuccess({ currOffset: offset, isMoreContacts: isMoreContacts, contact: contacts }));
        }
      }, error => {
        this.store.dispatch(new LoadContactsFail());
      });
      this.contactService.getAllDirectoryContact(0, 1, query, false,this.sortColumn,this.sortOrder).pipe(take(1)).subscribe(res => {
        this.store.select(getContactsCount).pipe(take(1)).subscribe(v => {
          console.log("contact.repository getAllGlobalContactsRes", res, v);
          this.store.dispatch(new SetContactsCount({ ...v, contacts_count:  res.total_count}));
        });
      });
    } else {
      console.log("contact.repository getAllGlobalContact offline");
      if (!query) {
        if (this.databaseService.dbReady.value) {
          this.databaseService.fetchContacts(true).subscribe(v => {
            console.log("[contact.repo] db.fetchContacts result:", v);
            let contacts: Contact[] = this.mapContacts(v);
            let isMoreContacts = false;
            let offset = contacts.length;
            this.store.dispatch(new LoadContactsSuccess({ currOffset: offset, isMoreContacts: isMoreContacts, contact: contacts }));
          });
        } else {
          setTimeout(() => {
            this.getAllGlobalContacts(query);
          }, 400);
        }
      }
    }
  }

  createContactGroup(body: any): void {
    const currentURL = this.getCurrentURL();
    this.contactService.createGroupContact(body).pipe(take(1)).subscribe(res => {
      if (!!res && res.contact_group) {
        this.broadcaster.broadcast(BroadcastKeys.HIDE_CONTACT_GROUP_CREATE_DIALOG);
        const group = res.contact_group;
        const contactGroup = this.mapContactGroup([group]);
        this.store.dispatch(new CreateGroupContactSuccess(contactGroup[0]));
        this.getStatistics();
        this.toastService.show("CONTACT_GROUP_CREATED");
      }
    }, error => {
      this.toastService.showPlainMessage(error);
    });
  }

  updateContactGroup(body: any): void {
    this.store.dispatch(new UpdateGroupContact());
    this.store.dispatch(new StartGroupLoading());
    this.contactService.updateContactGroup(body).pipe(take(1)).subscribe(res => {
      if (!!res && res.contact_group) {
        this.broadcaster.broadcast(BroadcastKeys.HIDE_CONTACT_GROUP_CREATE_DIALOG);
        const group = res.contact_group;
        const contactGroup = this.mapContactGroup([group]);
        this.store.dispatch(new UpdateGroupContactSuccess({ id: contactGroup[0].id, changes: contactGroup[0] }));
        this.toastService.show("CONTACT_GROUP_UPDATED_MESSAGE");
        this.broadcaster.broadcast(BroadcastKeys.RESET_CONTACT_LIST);
      }
      this.store.dispatch(new StopGroupLoading());
    }, error => {
      this.store.dispatch(new UpdateGroupContactFail());
      this.store.dispatch(new StopGroupLoading());
      this.toastService.showPlainMessage(error);
    });
  }

  getAllContactGroup(): void {
    let query: string = "";
    this.activatedRoute.queryParams.pipe(takeUntil(this.isAlive$))
    .subscribe((queryParams: any) => {
        query = queryParams.searchText;
    });
    setTimeout(() => {
      this.store.dispatch(new LoadGroupContacts());
    }, 0);
    let offset = 0;
    let include: string = "";
    if (this.isOnline) {
      console.log("contact.repo getAllContactGroup calling getAllDirectoryContactGroup");
      if (environment.isCordova || environment.isElectron) {
        include = "contacts";
      }
      // debug
      include = "contacts";
      this.contactService.getAllDirectoryContactGroup(offset, this.limit, query, include, this.sortOrder).pipe(take(1)).subscribe(res => {
        if (!!res && res.contact_groups) {
          const contactGroups = res.contact_groups;
          let contacts: Contact[] = [];
          contacts = this.mapContactGroup(contactGroups);
          let isMoreContacts = false;
          offset = contacts.length;
          if (contacts.length === this.limit) {
            isMoreContacts = true;
          } else {
            offset = 0;
            isMoreContacts = false;
          }
          this.store.dispatch(new LoadGroupContactsSuccess({ currOffset: offset, isMoreContacts: isMoreContacts, contact: contacts }));
        }
      }, error => {
        this.toastService.showPlainMessage(error);
        this.store.dispatch(new LoadGroupContactsFail());
      });
    } else {
      if (this.databaseService.dbReady.value) {
        this.databaseService.getAllContactGroup().subscribe(groups => {
          let contacts: Contact[] = [];
          contacts = this.mapContactGroup(groups);
          this.store.dispatch(new LoadGroupContactsSuccess({ currOffset: 0, isMoreContacts: false, contact: contacts }));
        });
      } else {
        setTimeout(() => {
          this.getAllContactGroup();
        }, 200);
      }
    }
  }

  createContact(body: any, createDuplicate?: boolean): void {
    const requestbody: any = _.clone(body, true);
    if (requestbody.is_company === "1") {
      delete requestbody.job_title;
      delete requestbody.middle_name;
      delete requestbody.last_name;
    }
    console.log("contact.repo createContact requestbody: ", requestbody);
    if (this.isOnline) {
      this.contactService.createContact(requestbody, createDuplicate).pipe(take(1)).subscribe(res => {
        console.log("[createContact]", createDuplicate, res);
        if (!!res && res.contact) {
          const newContact: any = this.mapContacts([res.contact]);
          const type = this.getCurrentRouteType();
          if (type === AppConstants.LIST) {
            this.store.dispatch(new CreateListContactSuccess(newContact[0]));
          } else if (type === AppConstants.TAG) {
            this.store.dispatch(new CreateTagContactSuccess(newContact[0]));
          } else {
            this.store.dispatch(new CreateContactSuccess(newContact[0]));
          }
          this.toastService.show("CONTACT_CREATED_MSG");
          this.broadcaster.broadcast(BroadcastKeys.SUCCESS_CREATE_UDATE_CONTACT_REQUEST);
          this.getContactTags();
          this.getContactList();
          this.getStatistics();
        } else if (!!res && res.duplicates) {
          const duplicateContacts = this.mapContacts(res.duplicates);
         console.log("duplicateContacts", duplicateContacts);
         let duplicateVariableTag = "EMAIL";
         for (let contact of duplicateContacts) {
             const duplicateContactPhones = contact?.phones;
             const duplicateContactFullName = contact?.fullName;
             for (let phoneObj of duplicateContactPhones) {
                 const duplicateContactPhoneNumber = phoneObj?.phone;
                 const phoneMatch = requestbody?.phones_attributes.find(item => item?.phone === duplicateContactPhoneNumber);
                 if (phoneMatch) {
                     duplicateVariableTag = "PHONE";
                     break;
                 }
             }
             const requestBodyFullName = ContactUtils?.getFullName(requestbody?.first_name,requestbody?.last_name);
             if (requestBodyFullName === duplicateContactFullName) {
                 duplicateVariableTag = "NAME";
                 break;
             }
         }
         const dlg = this.matDialog.open(ConfirmationDialogComponent, {
            maxWidth: "100%",
            autoFocus: false,
            panelClass: "confirm_contactplus_dialog",
           data: { dialogType: ConfirmDialogType?.DUPLICATE_CONTACT, total: res?.total_count, message_title: "DUPLICATE_CONTACTS", message_body: this.getMessageBodyForDuplicateTag(duplicateVariableTag), duplicateTag: duplicateVariableTag }
            });
          dlg.afterClosed().pipe(take(1)).subscribe(res => {
              if (!!res && res.confirmation) {
                  if (res.confirmation === "yes") {
                    this.createContact(body, true);
                  } else {
                    this.broadcaster.broadcast(BroadcastKeys.CREATE_UPDATE_REQUEST_FAIL);
                  }
              }
          });
        }
      }, error => {
        this.toastService.showPlainMessage(error);
        this.broadcaster.broadcast(BroadcastKeys.CREATE_UPDATE_REQUEST_FAIL);
      });
    } else {
      const contactBody: any = { contact: { "contact": requestbody } };
      if (createDuplicate) {
        contactBody.contact.create_duplicate = "1";
      }
      this.databaseService.addPendingOp(contactBody,"createContact").subscribe(res => {
        console.log("offlinestoredop createContact res: ", res);
        let pendingContact = res.operation.contact.contact;
        pendingContact.id = -1 * res.id;
        const newContact: any = this.mapContacts([pendingContact]);
        const type = this.getCurrentRouteType();
        console.log("offlinestoredop createContact newContact: ", newContact);
        if (type === AppConstants.LIST) {
          this.store.dispatch(new CreateListContactSuccess(newContact[0]));
        } else if (type === AppConstants.TAG) {
          this.store.dispatch(new CreateTagContactSuccess(newContact[0]));
        } else {
          this.store.dispatch(new CreateContactSuccess(newContact[0]));
          this.mapContactRequestToDBcontact(pendingContact, newContact[0].is_global).subscribe(contactToStore => {

            const newContact2: any = this.mapContacts([contactToStore]);
            this.store.dispatch(new UpdateContactSuccess({ id: newContact2[0].id, changes: newContact2[0] }));
            this.databaseService.createOrUpdateContacts([contactToStore]).subscribe(() => {
              this.broadcaster.broadcast(BroadcastKeys.LOAD_CONTACTS_FROM_STORE, "fcm");
              //this.databaseService.getContactsByList()
            });
          });
        }
        this.toastService.show("CONTACT_CREATED_MSG");
        this.broadcaster.broadcast(BroadcastKeys.SUCCESS_CREATE_UDATE_CONTACT_REQUEST);
        this.getContactTags();
        this.getContactList();
        this.getStatistics();
      });
      // database.addPendingOp
    }

  }

  private getMessageBodyForDuplicateTag(tag: string) {
      if (tag == "NAME") {
        return "DUPLICATE_CONTACTS_MSG_SINGULAR_NAME";
      }
      else if (tag == "EMAIL") {
        return "DUPLICATE_CONTACTS_MSG_SINGULAR_EMAIL";
      }
      else if (tag == "PHONE") {
        return "DUPLICATE_CONTACTS_MSG_SINGULAR_PHONE";
      }
  }

  public loadMoreContacts(query?: string, isGlobal?: boolean, sortOrder?) {
    console.log("contact.repository loadMoreContacts ", query);
    let nextPageLoading: boolean;
    let offset = 0;
    let isMoreContacts = false;
    this.store.select(getContactsCurrentPageOffset).pipe(take(1)).subscribe(o => offset = o);
    this.store.select(getIsMoreContacts).pipe(take(1)).subscribe(l => isMoreContacts = l);
    if (nextPageLoading) {
      return;
    }
    if (!isMoreContacts) {
      return;
    }
    this.store.dispatch(new NextLoadContacts());
    this.contactService.getAllDirectoryContact(offset, this.limit, query, isGlobal,this.sortColumn,this.sortOrder).subscribe(res => {
      if (!!res && res !== null && res.contacts) {
        let contacts: Contact[] = [];
        contacts = this.mapContacts(res.contacts);
        let newOffset = offset + contacts.length;
        if (contacts.length === this.limit) {
          isMoreContacts = true;
        }
        else {
          newOffset = 0;
          isMoreContacts = false;
        }
        this.store.dispatch(new NextLoadContactsSuccess({
          currOffset: newOffset,
          isMoreContacts: isMoreContacts,
          contacts: contacts as Contact[]
        }));
      }
    }, err => {
      if (err === undefined) {
        this.store.dispatch(new NextLoadContactsFail());
        this.toastService.show("NETWORK_CONNECTION_LOST");
        return;
      }
      this.store.dispatch(new NextLoadContactsFail());
      this.toastService.showPlainMessage(err);
    });
  }


  mapContacts(contacts: any): Contact[] {
    const contactsItems: Contact[] = [];
    contacts.map(item => {
      const mapItem = ContactUtils.getContacts(item);
      contactsItems.push(mapItem);
    });
    return contactsItems;
  }

  deleteContact(checkedContacts: Contact[]): void {
    const routeType = this.getCurrentRouteType();
    const withoutGlobalContacts = checkedContacts.filter(c => !c.is_global);
    const ids = withoutGlobalContacts.map(c => c.id);
    if (this.isOnline) {
      this.contactService.deleteBulkContact(ids).pipe(take(1)).subscribe(res => {
        if (!!res && res.success) {
            this.broadcaster.broadcast("RESET_CONTACT_PLUS_LIST");
            if (routeType === AppConstants.LIST) {
              this.store.dispatch(new RemoveMultipleListContactSuccess(ids));
            } else if (routeType === AppConstants.TAG) {
              this.store.dispatch(new RemoveMultipleTagContactSuccess(ids));
            } else {
              const id = this.getCurrentRouteId();
              if (id === AppConstants.FREQUENTLY_CONTACTED) {
                this.store.dispatch(new RemoveMultipleFrequntlyContactSuccess(ids));
              } else {
                this.store.dispatch(new RemoveMultipleContactSuccess(ids));
              }
            }
            this.toastService.show("CONTACT_DELETE_SUCCESSFULLY");
            this.broadcaster.broadcast(BroadcastKeys.RESET_CONTACT_LIST);
            this.broadcaster.broadcast(BroadcastKeys.HIDE_CONTACT_DETAIL_DIALOG);
            this.getStatistics();
            this.deleteGroupContactMember(checkedContacts);
        }
      }, error => {
        this.toastService.showPlainMessage(error);
      });
    } else {
      this.databaseService.addPendingOps(withoutGlobalContacts, "deleteContact").subscribe(res => {
        this.broadcaster.broadcast("RESET_CONTACT_PLUS_LIST");
        if (routeType === AppConstants.LIST) {
          this.store.dispatch(new RemoveMultipleListContactSuccess(ids));
        } else if (routeType === AppConstants.TAG) {
          this.store.dispatch(new RemoveMultipleTagContactSuccess(ids));
        } else {
          const id = this.getCurrentRouteId();
          if (id === AppConstants.FREQUENTLY_CONTACTED) {
            this.store.dispatch(new RemoveMultipleFrequntlyContactSuccess(ids));
          } else {
            console.log("REMOVEMULTIPLE: ", ids);
            this.store.dispatch(new RemoveMultipleContactSuccess(ids));
            this.databaseService.deleteContacts(ids).subscribe(() => {
              this.databaseService.getContactLists().subscribe(oldlists => {
                if (!!oldlists && (oldlists.length > 0)) {
                  oldlists.forEach(oldlist => {
                    this.databaseService.getContactsByList(oldlist.id).subscribe(list => {
                      console.log("offlinestoredop updateContact contactToStore list: ", oldlist.id, list);
                      const updatedListCount = !!list ? list.length : 0;
                      this.databaseService.getContactListById(oldlist.id).subscribe(oldList => {
                        const list2store = { ...oldList };
                        list2store["contacts_count"] = updatedListCount;
                        this.databaseService.createOrUpdateContactFolders([list2store]).subscribe(() => {
                          this.getContactList();
                          this.getStatistics();
                        });
                      });
                    });
                  });
                }
              });
            });
          }
        }
        this.toastService.show("CONTACT_DELETE_SUCCESSFULLY");
        this.broadcaster.broadcast(BroadcastKeys.RESET_CONTACT_LIST);
        this.broadcaster.broadcast(BroadcastKeys.HIDE_CONTACT_DETAIL_DIALOG);
        this.getStatistics();

        // this.deleteGroupContactMember(checkedContacts);

      });
    }
  }

  mergeInternalContact(checkedContacts: Contact[]): void {
    const globalContacts = checkedContacts.filter(c => c.is_global === true);
    if ( globalContacts && globalContacts.length > 0) {
      this.toastService.show("GLOBAL_SELECTED_CONTACT_MERGE_ERROR");
      return;
    }
    const ids = checkedContacts.map(c => c.id);
    this.contactService.mergeInternalContact(ids).pipe(take(1)).subscribe(res => {
      if (!!res && res.success) {
          this.broadcaster.broadcast("RESET_CONTACT_PLUS_LIST");
          this.toastService.show("CONTACT_MERGE_SUCCESSFULLY");
          this.broadcaster.broadcast(BroadcastKeys.RESET_CONTACT_LIST);
          this.getAllContact();
          this.getStatistics();
      }
    }, error => {
      this.toastService.showPlainMessage(error);
    });
  }

  updateContact(contact: any, isFavorite): void {
    const currentType = this.getCurrentRouteType();
    const id = this.getCurrentRouteId();
    if (currentType === AppConstants.LIST) {
      this.store.dispatch(new UpdateListContact());
      this.store.dispatch(new StartListLoading());
    } else if (currentType === AppConstants.TAG) {
      this.store.dispatch(new UpdateTagContact());
      this.store.dispatch(new StartTagLoading());
    } else {
      const id = this.getCurrentRouteId();
      if (id === AppConstants.FREQUENTLY_CONTACTED) {
        this.store.dispatch(new UpdateFrequntlyContact());
        this.store.dispatch(new StartFrequntlyLoading());
      } else {
        this.store.dispatch(new UpdateContact());
        this.store.dispatch(new StartLoading());
      }
    }
    const requestbody: any = _.clone(contact, true);
    if (requestbody.is_company === "1") {
      delete requestbody.job_title;
      delete requestbody.middle_name;
      delete requestbody.last_name;
    }
    if (this.isOnline) {
      this.contactService.updateContact(requestbody).pipe(take(1)).subscribe(res => {
        if (!!res && res.contact) {
          let isRemoveFromStore: boolean = false;
          const newContact = this.mapContacts([res.contact]);
          const newContact0 = newContact[0];
          const cmail = !!newContact0.jid ? newContact0.jid : (!!newContact0.emails ? newContact0.emails[0]?.email : "");
          const cemail = !!newContact0.jid ? newContact0.jid : cmail;
          this.databaseService.deleteAvatar(cemail).pipe(take(1)).subscribe(() => {
            this.broadcaster.broadcast("CONTACTAVATARUPDATED", cemail);
          });
          let type: string = "";
          let routeId: string = "";
          this.store.select(getRouteType).pipe(take(1)).subscribe(routeType => {
            type = routeType;
          });
          this.store.select(getRouteId).pipe(take(1)).subscribe(routeID => {
            routeId = routeID;
          });
          const contact = newContact[0];
          if (type === AppConstants.LIST) {
            if (!!contact.contact_list && contact.contact_list.length > 0) {
              const isListAvailable = contact.contact_list.filter(cl => cl.id.toString() === routeId)[0];
              if (!!isListAvailable) {
                isRemoveFromStore = false;
              } else {
                isRemoveFromStore = true;
              }
            } else {
              isRemoveFromStore = true;
            }
          } else if (type === AppConstants.TAG) {
            if (!!contact.tags && contact.tags.length > 0) {
              const isTagAvailable = contact.tags.filter(tag => tag.id.toString() === routeId)[0];
              if (!!isTagAvailable) {
                isRemoveFromStore = false;
              } else {
                isRemoveFromStore = true;
              }
            } else {
              isRemoveFromStore = true;
            }
          }
          console.log("[isRemoveFromStore]" , isRemoveFromStore);
          if (!isRemoveFromStore) {
            if (currentType === AppConstants.LIST) {
              console.log("updateContact-dispatch UpdateContactSuccess: ", { id: newContact[0].id, changes: newContact[0] });
              this.store.dispatch(new UpdateListContactSuccess({ id: newContact[0].id, changes: newContact[0] }));
            } else if (currentType === AppConstants.TAG) {
              this.store.dispatch(new UpdateTagContactSuccess({ id: newContact[0].id, changes: newContact[0] }));
            } else  {
              if (routeId === AppConstants.FREQUENTLY_CONTACTED) {
                this.store.dispatch(new UpdateFrequntlyContactSuccess({ id: newContact[0].id, changes: newContact[0] }));
              } else {
                console.log("updateContact-dispatch UpdateContactSuccess: ", { id: newContact[0].id, changes: newContact[0] });

                this.store.dispatch(new UpdateContactSuccess({ id: newContact[0].id, changes: newContact[0] }));
              }
            }
          } else {
            if (currentType === AppConstants.LIST) {
              this.store.dispatch(new RemoveMultipleListContactSuccess([newContact[0].id]));
              this.broadcaster.broadcast(BroadcastKeys.CLOSE_LIST_DETAIL_VIEW);
            } else if (currentType === AppConstants.TAG) {
              this.store.dispatch(new RemoveMultipleTagContactSuccess([newContact[0].id]));
              this.broadcaster.broadcast(BroadcastKeys.CLOSE_LIST_DETAIL_VIEW);
            } else {
              if (routeId === AppConstants.FREQUENTLY_CONTACTED) {
                this.store.dispatch(new RemoveMultipleFrequntlyContactSuccess([newContact[0].id]));
              } else {
                this.store.dispatch(new RemoveMultipleContactSuccess([newContact[0].id]));
              }
            }
          }
          this.broadcaster.broadcast(BroadcastKeys.SUCCESS_CREATE_UDATE_CONTACT_REQUEST);
          this.toastService.show("CONTACT_UPDATED_MSG");
          this.broadcaster.broadcast(BroadcastKeys.RESET_CONTACT_LIST);
          this.getContactTags();
          this.getContactList();
          this.getStatistics();
          this.updateGroupMember(newContact[0]);
        }
        if (currentType === AppConstants.LIST) {
          this.store.dispatch(new StopListLoading());
        } else if (currentType === AppConstants.TAG) {
          this.store.dispatch(new StopTagLoading());
        } else {
          if (id === AppConstants.FREQUENTLY_CONTACTED) {
            this.store.dispatch(new StopFrequntlyLoading());
          } else {
            this.store.dispatch(new StopLoading());
          }
        }
      }, error => {
        if (currentType === AppConstants.LIST) {
          this.store.dispatch(new UpdateListContactFail());
          this.store.dispatch(new StopListLoading());
        } else if (currentType === AppConstants.TAG) {
          this.store.dispatch(new UpdateTagContactFail());
          this.store.dispatch(new StopTagLoading());
        } else {
          if (id === AppConstants.FREQUENTLY_CONTACTED) {
            this.store.dispatch(new UpdateFrequntlyContactFail());
            this.store.dispatch(new StopFrequntlyLoading());
          } else {
            this.store.dispatch(new UpdateContactFail());
            this.store.dispatch(new StopLoading());
          }
        }
        this.broadcaster.broadcast(BroadcastKeys.CREATE_UPDATE_REQUEST_FAIL);
        this.toastService.showPlainMessage(error);
      });
    } else {
      if (contact.id < 0) { // contact is offline created and still pending, so replace the pending op
        const pendingOpId = contact.id * -1;
        this.databaseService.deletePendingOpById(pendingOpId).subscribe(() => {
          delete requestbody.id;
          const contactBody: any = { contact: { "contact": requestbody } };
          this.databaseService.addPendingOp(contactBody, "createContact").subscribe(res => {
            console.log("offlinestoredop updateContact res: ", res);
            let pendingContact = res.operation.contact.contact;
            pendingContact.id = -1 * res.id;
            const newContact: any = this.mapContacts([pendingContact]);
            const type = this.getCurrentRouteType();
            console.log("offlinestoredop updateContact newContact: ", newContact);
            const ids = [contact.id];
            console.log("REMOVEMULTIPLE: ", ids);
            this.store.dispatch(new RemoveMultipleContactSuccess(ids));
            this.databaseService.deleteContact({ id: contact.id }).subscribe();
            if (type === AppConstants.LIST) {
              this.store.dispatch(new CreateListContactSuccess(newContact[0]));
            } else if (type === AppConstants.TAG) {
              this.store.dispatch(new CreateTagContactSuccess(newContact[0]));
            } else {
              this.store.dispatch(new CreateContactSuccess(newContact[0]));
              this.mapContactRequestToDBcontact(pendingContact, newContact[0].is_global).subscribe(contactToStore => {

                // console.log("offlinestoredop updateContact contactToStore: ", contactToStore);
                this.databaseService.createOrUpdateContacts([contactToStore]).subscribe(() => {
                  this.getContactList();
                  this.getStatistics();
                });
              });

            }
            this.broadcaster.broadcast(BroadcastKeys.SUCCESS_CREATE_UDATE_CONTACT_REQUEST);
            this.toastService.show("CONTACT_UPDATED_MSG");
            this.broadcaster.broadcast(BroadcastKeys.RESET_CONTACT_LIST);
            this.getContactTags();
            this.getContactList();
            this.getStatistics();
          });
        });
      } else {
        // check if pending updates are stored and remove them
        this.databaseService.getPendingOpsByContact(contact.id).subscribe(op => {
          if (op.length > 0) {
            for (let i = 0; i < op.length; i++) {
              this.databaseService.deletePendingOpById(op[i].id).subscribe();
            }
          }
        });
        let type: string = "";
        let routeId: string = "";
        this.store.select(getRouteType).pipe(take(1)).subscribe(routeType => {
          type = routeType;
        });
        this.store.select(getRouteId).pipe(take(1)).subscribe(routeID => {
          routeId = routeID;
        });

        const contactBody: any = { contact: { "contact": requestbody } };
        this.databaseService.addPendingOp(contactBody, "updateContact").subscribe(res => {
          console.log("offlinestoredop updateContact res: ", res);
          let pendingContact = res.operation.contact.contact;
          const newContact: any = this.mapContacts([pendingContact]);
          const type = this.getCurrentRouteType();
          console.log("offlinestoredop updateContact newContact: ", newContact);
          if (type === AppConstants.LIST) {
            this.store.dispatch(new UpdateListContactSuccess({ id: newContact[0].id, changes: newContact[0] }));
          } else if (type === AppConstants.TAG) {
            this.store.dispatch(new UpdateTagContactSuccess({ id: newContact[0].id, changes: newContact[0] }));
          } else {
            this.store.dispatch(new UpdateContactSuccess({ id: newContact[0].id, changes: newContact[0] }));
          }

          this.mapContactRequestToDBcontact(pendingContact, newContact[0].is_global).subscribe(contactToStore => {
            const newContact2: any = this.mapContacts([contactToStore]);
            this.store.dispatch(new UpdateContactSuccess({ id: newContact2[0].id, changes: newContact2[0] }));

            if (type === AppConstants.LIST) {
              this.store.dispatch(new UpdateListContactSuccess({ id: newContact[0].id, changes: newContact[0] }));
              let contact_list_ids = [];
              if (!!contact.contact_list_ids && contact.contact_list_ids.length > 0) {
                contact_list_ids = contact.contact_list_ids.map(l => l.toString());
                if (contact_list_ids.indexOf(routeId) === -1) {
                  this.store.dispatch(new RemoveMultipleListContactSuccess([newContact[0].id]));
                }
              }
            }

            this.databaseService.createOrUpdateContacts([contactToStore]).subscribe(() => {
              this.databaseService.getContactLists().subscribe(oldlists => {
                if (!!oldlists && (oldlists.length > 0)) {
                  oldlists.forEach(oldlist => {
                    this.databaseService.getContactsByList(oldlist.id).subscribe(list => {
                      console.log("offlinestoredop updateContact contactToStore list: ", oldlist.id, list);
                      const updatedListCount = !!list ? list.length : 0;
                      this.databaseService.getContactListById(oldlist.id).subscribe(oldList => {
                        const list2store = { ...oldList };
                        list2store["contacts_count"] = updatedListCount;
                        this.databaseService.createOrUpdateContactFolders([list2store]).subscribe(() => {
                          this.getContactList();
                          this.broadcaster.broadcast(BroadcastKeys.RESET_CONTACT_LIST);
                          this.broadcaster.broadcast("OFFLINE_LAYOUT_UPDATE");
                        });
                      });
                    });
                  });
                }
              });
            });
          });

          this.broadcaster.broadcast(BroadcastKeys.SUCCESS_CREATE_UDATE_CONTACT_REQUEST);
          this.toastService.show("CONTACT_UPDATED_MSG");
          this.broadcaster.broadcast(BroadcastKeys.RESET_CONTACT_LIST);
          this.getContactTags();
          this.getContactList();
          this.getStatistics();
        });
      }

    }
  }

  mapContactGroup(groupContact: any): Contact[] {
    const contactsItems: Contact[] = [];
    groupContact.map(item => {
      const mapItem = ContactUtils.getContactGroup(item);
      contactsItems.push(mapItem);
    });
    return contactsItems;
  }

  deleteContactGroup(checkedContacts: Contact[]): void {
    const withoutGlobalContacts = checkedContacts.filter(c => !c.is_global);
    const deleteContact: any[] = [];
    withoutGlobalContacts.map(c => {
      deleteContact.push(this.contactService.deleteContactGroup(c.id));
    });
    const ids = withoutGlobalContacts.map(c => c.id);
    forkJoin(deleteContact).pipe(take(1)).subscribe(res => {
      this.broadcaster.broadcast("RESET_CONTACT_PLUS_LIST");
      this.store.dispatch(new RemoveMultipleGroupContactSuccess(ids));
      this.toastService.show("DELETE_GROUP_SUCCESS");
      this.broadcaster.broadcast(BroadcastKeys.RESET_CONTACT_LIST);
      this.getStatistics();
    }, error => {
      this.toastService.showPlainMessage(error);
    });
  }

  saveUserSettings(setting: any): void {
    this.contactService.saveSettings(setting).pipe(take(1)).subscribe((res: AuthUser) => {
      const user = res;
      this.loadLanguage(user.language);
      this.localStorage.setItem(AppConstants.PROFILE_USER, JSON.stringify(user));
      this.store.dispatch(new SetUserProfile(user));
      this.broadcaster.broadcast(BroadcastKeys.HIDE_CONTACT_SETTINGS_DIALOG);
      this.broadcaster.broadcast("CHANGE_SETTING_UPDATE");
      this.toastService.show("SETTINGS_HAS_BEEN_SAVE");
    }, error => {
      this.toastService.showPlainMessage(error);
    });
  }

  public loadLanguage(selectedLanguage: string): void {
    if (!selectedLanguage) {
      selectedLanguage = "en";
    }
    this.translate.use(selectedLanguage);
    this.translate.reloadLang(selectedLanguage);
    this.localStorage.setItem(AppConstants.CONTACT_LANGUAGE, selectedLanguage);
    this.configService.language = selectedLanguage;
  }

  public poppulateAuthUserAvatar(user: AuthUser, forceResync: boolean): Observable<boolean> {
    const response = new BehaviorSubject(null);
    const currentTimeStamp = new Date().getTime();
    const avatarServerUrl = this.serverUrlAvatar(user.id, currentTimeStamp);
    user.userAvatar = avatarServerUrl;
    response.next(true);
    return response.asObservable().pipe(take(2));
  }

  private performAvatarsAssignement(objects: any[], forceResync: boolean, avatarPropertyName: string, type: number): Observable<boolean> {
    const response = new Subject<boolean>();
    const currentTimeStamp = new Date().getTime();
    const serverAvatarsUrls = [];
    const uniqueUsersIds = [];
    const userObjectsIndexes = {};

    objects.forEach((obj, idx) => {
      const uid = this.avatarObjectId(obj, type);
      if (uid > 0) {
        // collect unique users
        if (uniqueUsersIds.indexOf(uid) === -1) {
          uniqueUsersIds.push(uid);
          // collect server avatar urls
          const serverUrlAvatar = this.serverUrlAvatar(uid, currentTimeStamp);
          serverAvatarsUrls.push(serverUrlAvatar);
        }
        // group users and they objects
        const grouppedObjects = userObjectsIndexes[uid];
        if (grouppedObjects) {
          grouppedObjects.push(idx);
        } else {
          userObjectsIndexes[uid] = [idx];
        }
      }
    });
    return response.asObservable().pipe(take(1));
  }

  private avatarObjectId(objectToAssignAvatar: any, type: number): any {
    if (type === 1){
      return objectToAssignAvatar.id;
    } else  if (type === 2){
      return objectToAssignAvatar.assigned_to ? objectToAssignAvatar.assigned_to.id : 0;
    } else  if (type === 3){
      return objectToAssignAvatar.user.id;
    }
  }

  public serverUrlAvatar(userId: number, currentTimeStamp: number): string {
    let url;
    if (environment.isCordova || environment.isElectron) {
      url = this.contactService.getAvatarURL(userId) + "&timestamp=" + currentTimeStamp;
    } else {
      url = this.contactService.getAvatarURL(userId) + "?timestamp=" + currentTimeStamp;
    }
    return url;
  }

  public updateUserAvatar(img: any) {
    this.contactService.updateUserAvatar(img).pipe(take(1)).subscribe(res => {
      this.broadcaster.broadcast("UPDATE_USER_AVATAR");
    }, err => {
      this.toastService.showPlainMessage(err);
    });
  }

  getCurrentURL(): string {
   return this.router.routerState.snapshot.url;
  }

  removeProfileAvatar(): void {
    this.contactService.removeProfileAvatar().pipe(take(1)).subscribe(res => {
      this.broadcaster.broadcast("UPDATE_USER_AVATAR");
    }, error => {
      this.toastService.showPlainMessage(error);
    });
  }

  public loadMoreContactGroup(query?: string) {
    let nextPageLoading: boolean;
    let offset = 0;
    let isMoreContacts = false;
    this.store.select(getGroupContactsCurrentPageOffset).pipe(take(1)).subscribe(o => offset = o);
    this.store.select(getIsMoreGroupContacts).pipe(take(1)).subscribe(l => isMoreContacts = l);
    if (nextPageLoading) {
      return;
    }
    if (!isMoreContacts) {
      return;
    }
    this.store.dispatch(new NextLoadGroupContacts());
    console.log("contact.repo loadMoreContactGroup calling getAllDirectoryContactGroup");
    this.contactService.getAllDirectoryContactGroup(offset, this.limit).subscribe(res => {
      if (!!res && res !== null && res.contact_groups) {
        const contactGroups = res.contact_groups;
        let contacts: Contact[] = [];
        contacts = this.mapContactGroup(contactGroups);
        let newOffset = offset + contacts.length;
        if (contacts.length === this.limit) {
          isMoreContacts = true;
        } else {
          newOffset = 0;
          isMoreContacts = false;
        }
        this.store.dispatch(new NextLoadGroupContactsSuccess({
          currOffset: newOffset,
          isMoreContacts: isMoreContacts,
          contacts: contacts as Contact[]
        }));
      }
    }, err => {
      if (err === undefined) {
        this.store.dispatch(new NextLoadGroupContactsFail());
        this.toastService.show("NETWORK_CONNECTION_LOST");
        return;
      }
      this.store.dispatch(new NextLoadGroupContactsFail());
      this.toastService.showPlainMessage(err);
    });
  }

  getSearchContactGroup(query: string): void {
    this.store.dispatch(new LoadGroupContacts());
    let offset = 0;
    this.contactService.getAllSearchContactGroup(offset, this.limit, query).pipe(take(1)).subscribe(res => {
      if (!!res && res !== null && res.contact_groups) {
        let contacts: Contact[] = [];
        contacts = this.mapContactGroup(res.contact_groups);
        let isMoreContacts = false;
        offset = contacts.length;
        if (contacts.length === this.limit) {
          isMoreContacts = true;
        } else {
          offset = 0;
          isMoreContacts = false;
        }
        this.store.dispatch(new LoadGroupContactsSuccess({ currOffset: offset, isMoreContacts: isMoreContacts, contact: contacts }));
      }
    }, error => {
      this.store.dispatch(new LoadGroupContactsFail());
    });
  }

  createContactList(folderTitle: string, color?: string) {
    this.store.dispatch(new CreateContactFolder());
    this.contactService.createContactList(folderTitle, color).subscribe(contactFolder => {
      this.toastService.show("CREATE_LIST_SUCCESS");
      this.store.dispatch(new CreateContactFolderSuccess(contactFolder));
    }, err => {
      if (err === "Name has already been taken") {
        this.toastService.show("LIST_EXIST_ERROR_MSG");
      } else {
        this.toastService.showPlainMessage(err);
      }
      this.store.dispatch(new CreateContactFolderFail());
    });
  }

  updateContactList(folder: ContactFolder) {
    this.store.dispatch(new UpdateContactFolder());
    this.contactService.updateContactList(folder).subscribe(contactFolder => {
      this.toastService.show("UPDATE_LIST_SUCCESS");
      this.store.dispatch(new UpdateContactFolderSuccess(contactFolder));
      let changes: any = [];
      /* Update Contact List in contact */
      const type = this.getCurrentRouteType();
      if (type === AppConstants.LIST) {
        this.store.select(getListContacts).pipe(take(1)).subscribe((contacts: Contact[]) => {
          if (!!contacts && contacts.length > 0) {
            contacts.map(c => {
              if (c.contact_list && c.contact_list.length > 0) {
                c.contact_list.map(cl => {
                  if (cl.id === contactFolder.id) {
                    cl.name = contactFolder.name;
                    cl.color_hex = contactFolder.color_hex === null ? null : contactFolder.color_hex;
                    changes.push({id: c.id, changes: c });
                  }
                });
              }
            });
          }
        });
        /* Update contact store after contact list update in contact */
        if (changes.length) {
          this.store.dispatch(new UpdateMultipleListContactSuccess(changes));
        }
      }
      if (type !== AppConstants.LIST) {
        this.store.select(getContacts).pipe(take(1)).subscribe((contacts: Contact[]) => {
          if (!!contacts && contacts.length > 0) {
            contacts.map(c => {
              if (c.contact_list && c.contact_list.length > 0) {
                c.contact_list.map(cl => {
                  if (cl.id === contactFolder.id) {
                    cl.name = contactFolder.name;
                    cl.color_hex = contactFolder.color_hex === null ? null : contactFolder.color_hex;
                    changes.push({id: c.id, changes: c });
                  }
                });
              }
            });
          }
        });
        /* Update contact store after contact list update in contact */
        if (changes.length) {
          this.store.dispatch(new UpdateMultipleContactSuccess(changes));
        }
      }
      this.getContactList();
    }, err => {
      if (err === "Name has already been taken") {
        this.toastService.show("LIST_EXIST_ERROR_MSG");
      } else {
        this.toastService.showPlainMessage(err);
      }
      this.store.dispatch(new UpdateContactFolderFail());
    });
  }

  deleteContactList(folder: ContactFolder) {
    this.store.dispatch(new DeleteContactFolder());
    this.contactService.deleteContactList(folder).subscribe(res => {
      this.toastService.show("DELETE_LIST_SUCCESS");
      this.store.dispatch(new DeleteContactFolderSuccess(folder));
      let folderId: string = "";
      let isListFolder: boolean = false;
      this.store.select(getRouteId).pipe(take(1)).subscribe(routeID => {
        folderId = routeID;
      });
      this.store.select(getRouteType).pipe(take(1)).subscribe(routeType => {
        if (routeType === AppConstants.LIST) {
          isListFolder = true;
        }
      });
      if (isListFolder && folderId === folder.id.toString()) {
        this.router.navigate(["/contactplus"]);
      } else {
        let changes: any = [];
        /* Update Contact List in contact */
        this.store.select(getContacts).pipe(take(1)).subscribe((contacts: Contact[]) => {
        if (!!contacts && contacts.length > 0) {
          contacts.map(c => {
            if (c.contact_list && c.contact_list.length > 0) {
              const listAfterDelete = c.contact_list.filter(cl => cl.id !== folder.id);
              c.contact_list = listAfterDelete;
              changes.push({id: c.id, changes: c });
            }
          });
        }
      });
        /* Update contact store after contact list update in contact */
        if (changes.length) {
          this.store.dispatch(new UpdateMultipleContactSuccess(changes));
        }
      }
      this.broadcaster.broadcast(BroadcastKeys.HIDE_CONTACT_LIST_DIALOG);
    }, err => {
      this.store.dispatch(new DeleteContactFolderFail());
    });
  }

  getContactList() {
    if (this.isOnline) {
      this.store.dispatch(new LoadContactFolders());
      this.contactService.getContactList().subscribe(contactFolders => {
        console.log("contact.repo getContactLists online: ", contactFolders);
        this.store.dispatch(new LoadContactFoldersSuccess(contactFolders));
      }, err => {
        this.store.dispatch(new LoadContactFoldersFail());
      });
    } else {
      if (this.databaseService.dbReady.value) {
        this.store.dispatch(new LoadContactFolders());
        this.databaseService.getContactLists().subscribe(contactFolders => {
          const resFolders = _.orderBy(contactFolders, "name", "asc");
          console.log("contact.repo getContactLists offline: ", contactFolders, resFolders);
          this.store.dispatch(new LoadContactFoldersSuccess(resFolders));
        }, err => {
          this.store.dispatch(new LoadContactFoldersFail());
        });
      } else {
        setTimeout(() => {
          this.getContactList();
        }, 300);
      }
    }
  }

  getContactTags() {
    this.store.dispatch(new LoadContactTags());
    if (this.isOnline) {
      console.log("contact.repo getContactTags");
      this.contactService.getContactTags().subscribe(contactTags => {
        this.store.dispatch(new LoadContactTagsSuccess(contactTags));
      }, err => {
        this.store.dispatch(new LoadContactTagsFail());
      });
    } else {
      this.store.dispatch(new LoadContactTagsSuccess([]));
    }
  }

  exportContacts(contat_filter: string, contact_type: string, export_type: string, contact_name?: string | undefined) {
    console.log("contacts.repo exportContacts: ", contat_filter, contact_type, export_type);
    if (environment.isCordova) {

      let headers = {};
      // we need this for HIN only ('if (environment.theme === "hin")'),
      // but it also works with a standard deployment, so we decided to keep it universal.
      try {
        const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
        if (!!loggedInUser && !!loggedInUser.secret) {
          headers = {
            "Authorization": loggedInUser.secret
          };
        }
      } catch (error) {
        console.error("contacts.repo exportContacts authheaders error: ", error);
      }
      // return this.http.get(this.getUrl() + "/contacts" + "." + export_type + contact_filter , { headers: this.getHeaders(), withCredentials: true, responseType: "blob" })
      const url = this.contactService.getUrl() + "/contacts" + "." + export_type + contat_filter;
      console.log("contacts.repo exportContacts cordova: ", url, headers);
      this.fileStorageService.downloadAndSaveFileInBackground(url, headers, CommonUtil.isOnIOS()).subscribe((localFileUrl) => {
        console.log("[contact.repo][downloadAndSaveFileInBackground] success, localFileUrl: ", localFileUrl);
        this.toastService.show("EXPORT_CONTACTS_SUCCESS");
      }, err => {
        console.error("[contact.repo][downloadAndSaveFileInBackground] err", err);
        this.toastService.show("EXPORT_CONTACTS_ERROR");
      });
    } else {
      this.contactService.exportContacts(contat_filter, contact_type, export_type).subscribe(blob => {
        if (contact_name) {contact_type = contact_name;}
        if (contact_type === "contacts" && contact_name === "selected_contacts") {contact_type = "selected_contacts";}
        this.saveData(blob, contact_type + "." + export_type, export_type);
        this.toastService.show("EXPORT_CONTACTS_SUCCESS");
      }, err => {
        this.toastService.show("EXPORT_CONTACTS_ERROR");
      });
    }

  }

  saveData(data, fileName, export_type) {
    if (environment.isCordova) {
      this.downloadFileToDownloadsOrGallery(data, fileName);
    } else {
      if (export_type === "vcf") {
        const blob = new Blob([data], { type: "text/vcard" });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = "contact.vcf";
        link.click();
      } else {
        const url = window.URL.createObjectURL(data);
        const a = document.createElement("a");
        document.body.appendChild(a);
        a.setAttribute("style", "display: none");
        a.href = url;
        a.download = fileName;
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
      }
    }
  }

  downloadFileToDownloadsOrGallery(fileBlob: any, fileName: string): Observable<any> {
    console.log("[downloadFileToDownloadsOrGallery][fileBlob]:", fileBlob);
    if (fileName.split(".")[0] === "") {
      fileName = new Date().getTime() + fileName;
    }
    console.log("[MailService] downloadFileToDownloadsOrGallery fileName: ", fileName);
    let fName = fileName.replace(/:/g, "-");
    const response = new Subject();
    let storageLocation = "";
    if (CommonUtil.isOnIOS()) {
      storageLocation = cordova.file.documentsDirectory;
    } else {
      storageLocation = cordova.file.externalDataDirectory;
    }
    const folderPath = storageLocation;
    window.resolveLocalFileSystemURL(
      folderPath,
      function(dir) {
        dir.getFile(
          fName,
          {
            create: true
          },
          function(file) {
            file.createWriter(
              function(fileWriter) {
                fileWriter.write(fileBlob);
                fileWriter.onwriteend = function() {
                  const url = file.toURL();
                  console.log("[url]: ", url);
                  cordova.plugins.fileOpener2.open(url, fileBlob.type === "application/json" ? "text/plain" : fileBlob.type, {
                    error: function error(err) {
                      console.error(err);
                      console.log("Unable to download 1");
                      alert("Unable to download");
                    },
                    success: function success() {
                      console.log("success with opening the file");
                    }
                  });
                };
                fileWriter.onerror = function(err) {
                  console.log("Unable to download 2");
                  alert("Unable to download");
                  console.error(err);
                };
              },
              function(err) {
                // failed
                alert("Unable to download");
                console.log("Unable to download 3");
                console.error(err);
              }
            );
          },
          function(err) {
            alert("Unable to download");
            console.log("Unable to download 4");
            console.error(err);
          }
        );
      },
      function(err) {
        alert("Unable to download");
        console.log("Unable to download 5");
        console.error(err);
      }
    );
    /* if (CommonUtil.isOnIOS()) {
      // save in hidden cache
      this.fileStorageService.saveBlobToDisc(fileBlob, fName).subscribe((localFileUrl) => {
        // for iOS we need to additionaly save to Camera Roll
        // this.toastService.show("DOWNLOAD_SUCCESS");
        cordova.plugins.imagesaver.saveImageToGallery(localFileUrl, () => {
          console.log("[MailService] downloadFileToDownloadsOrGallery success, localFileUrl: ", localFileUrl);
          response.next(localFileUrl);
        }, (err) => {
          response.error(err);
        });
      }, err => {
        response.error(err);
      });

      // Android
    } else {
      this.fileStorageService.saveBlobToAndroidDownloadFolder(fileBlob, fName).subscribe((localFileUrl) => {
        // this.toastService.show("DOWNLOAD_SUCCESS");
        console.log("[MailService] downloadFileToDownloadsOrGallery success, localFileUrl: ", localFileUrl);
        response.next(localFileUrl);
      }, err => {
        response.error(err);
      });
    } */
    return response.asObservable().pipe(take(1));
  }

  getContactFromList(id: string, query = "" , sortType = "asc"): void {
    setTimeout(() => {
      this.store.dispatch(new LoadListContacts());
    }, 0);
    let offset = 0;
    let isMoreContacts = false;
    let contacts: Contact[] = [];
    if (this.isOnline) {
      console.log("contact.repo getContactFromList online", id, navigator.onLine, this.isOnline);
      this.contactService.getContactListContact(id,query, this.sortColumn, sortType).pipe(take(1)).subscribe(res => {
        if (!!res && res !== null && res.contacts) {
          contacts = this.mapContacts(res.contacts);
          offset = contacts.length;
          if (contacts.length === this.limit) {
            isMoreContacts = true;
          } else {
            offset = 0;
            isMoreContacts = false;
          }
        }
        console.log("contact.repo getContactFromList online result: ", { currOffset: offset, isMoreContacts: isMoreContacts, contact: contacts } );
        this.store.dispatch(new LoadListContactsSuccess({ currOffset: offset, isMoreContacts: isMoreContacts, contact: contacts }));
      }, error => {
        this.store.dispatch(new LoadListContactsFail());
      });
    } else {
      console.log("contact.repo getContactFromList offline", id);
      this.databaseService.getContactsByList(id).pipe(take(1)).subscribe(res => {
        let contacts: Contact[] = this.mapContacts(res);
        console.log("contact.repo getContactFromList offline result: ", { currOffset: 0, isMoreContacts: false, contact: contacts } );
        this.store.dispatch(new LoadListContactsSuccess({ currOffset: 0, isMoreContacts: false, contact: contacts }));
      }, error => {
        this.store.dispatch(new LoadListContactsFail());
      });
    }
  }

  getContactFromListMore(id: string, query = "" , sortType = "asc", offset:number): void {
    setTimeout(() => {
      this.store.dispatch(new LoadListContacts());
    }, 0);

    let isMoreContacts = false;
    let contacts: Contact[] = [];
    if (this.isOnline) {
      console.log("contact.repo getContactFromList online", id, navigator.onLine, this.isOnline);
      this.contactService.getContactListContact(id,query, this.sortColumn, sortType, offset).pipe(take(1)).subscribe(res => {
        if (!!res && res !== null && res.contacts) {
          contacts = this.mapContacts(res.contacts);
          offset = contacts.length;
          if (contacts.length === this.limit) {
            isMoreContacts = true;
          } else {
            offset = 0;
            isMoreContacts = false;
          }
        }
        console.log("contact.repo getContactFromList online result: ", { currOffset: offset, isMoreContacts: isMoreContacts, contact: contacts } );
        this.store.dispatch(new NextLoadListContactsSuccess({ currOffset: offset, isMoreContacts: isMoreContacts, contacts: contacts }));
      }, error => {
        this.store.dispatch(new LoadListContactsFail());
      });
    } else {
      console.log("contact.repo getContactFromList offline", id);
      this.databaseService.getContactsByList(id).pipe(take(1)).subscribe(res => {
        let contacts: Contact[] = this.mapContacts(res);
        console.log("contact.repo getContactFromList offline result: ", { currOffset: 0, isMoreContacts: false, contact: contacts } );
        this.store.dispatch(new LoadListContactsSuccess({ currOffset: 0, isMoreContacts: false, contact: contacts }));
      }, error => {
        this.store.dispatch(new LoadListContactsFail());
      });
    }
  }

  getContactFromTag(id: string, query?: string, sortType = "asc"): void {
    setTimeout(() => {
      this.store.dispatch(new LoadTagContacts());
    }, 0);
    let offset = 0;
    let isMoreContacts = false;
    let contacts: Contact[] = [];
    this.contactService.getTagContact(id, query, this.sortColumn, sortType).pipe(take(1)).subscribe(res => {
      if (!!res && res !== null && res.contacts) {
        contacts = this.mapContacts(res.contacts);
        offset = contacts.length;
        if (contacts.length === this.limit) {
          isMoreContacts = true;
        } else {
          offset = 0;
          isMoreContacts = false;
        }
      }
      this.store.dispatch(new LoadTagContactsSuccess({ currOffset: offset, isMoreContacts: isMoreContacts, contact: contacts }));
    }, error => {
      this.store.dispatch(new LoadTagContactsFail());
    });
  }

  syncZimbraContacts() {
    let routingType;
    let routingId;
    this.store.select(getRouteType).pipe(take(1)).subscribe(o => routingType = o);
    this.store.select(getRouteId).pipe(take(1)).subscribe(l => routingId = l);
    this.contactService.syncZimbraContacts().pipe(take(1)).subscribe(res => {
      this.getContactList();
      if (routingType === AppConstants.CONTACTS) {
        this.getAllContact();
      } else if (routingType === AppConstants.LIST) {
        this.getContactFromList(routingId);
      } else if (routingType === AppConstants.TAG) {
        this.getContactFromTag(routingId);
      }
      this.toastService.show("SYNC_VNCMAIL_CONTACT_SUCCESS");
    }, err => {
      this.toastService.show("SYNC_VNCMAIL_CONTACT_FAILED");
    });
  }

  bulkUpdateContactList(contacts: Contact[], contactList: ContactFolder[]): void {
    const contactIds = contacts.map(c => c.id);
    const contactListIds = contactList.map(cl => cl.id);
    const body = {
      "ids": contactIds,
      "contact": {
          "contact_list_ids": contactListIds
      }
    };
    this.contactService.bulkUpdateListTags(body).pipe(take(1)).subscribe(res => {
      let changes: any = [];
      let allContactList: ContactFolder[] = [];
      this.store.select(getContactFolders).pipe(take(1)).subscribe( contactFolders => {
        if (!!contactFolders && contactFolders.length > 0) {
         contactList.map(cf => {
          const folder = contactFolders.filter(c => c.id.toString() === cf.id.toString())[0];
          if (!!folder) {
            allContactList.push(cf);
          }
         });
        }
      });
      contacts.forEach(contact => {
        if (!!contact.contact_list && contact.contact_list.length === 0) {
          contact.contact_list = allContactList;
        } else {
          if (!!contact.contact_list && contact.contact_list.length > 0) {
            allContactList.map(cl => {
              const list = contact.contact_list.filter(contactListItem => contactListItem.id.toString() === cl.id.toString())[0];
              if (list === undefined) {
                contact.contact_list.push(cl);
              }
            });
          }
        }
        changes.push({id: contact.id, changes: contact });
      });
      const type = this.getCurrentRouteType();
      if (type === AppConstants.LIST) {
        this.store.dispatch(new UpdateMultipleListContactSuccess(changes));
      } else if (type === AppConstants.TAG) {
        this.store.dispatch(new UpdateMultipleTagContactSuccess(changes));
      } else {
        this.store.dispatch(new UpdateMultipleContactSuccess(changes));
      }
      this.broadcaster.broadcast(BroadcastKeys.RESET_CONTACT_LIST);
      this.toastService.show("CONTACT_UPDATED_MSG");
    }, error => {
      this.toastService.showPlainMessage(error);
    });
  }

  bulkUpdateContactTags(contacts: Contact[], contactTags: ContactTag[]): void {
    const contactIds = contacts.map(c => c.id);
    const selectedTags = contactTags.map(cl => cl.name).toString();
    const body = {
      "ids": contactIds,
      "contact": {
          "tag_list": selectedTags
      }
    };
    this.contactService.bulkUpdateListTags(body).pipe(take(1)).subscribe(res => {
      this.updateBulkTagContact(contacts, contactTags);
      this.broadcaster.broadcast(BroadcastKeys.RESET_CONTACT_LIST);
    }, error => {
      this.toastService.showPlainMessage(error);
    });
  }

  updateBulkTagContact(contacts: Contact[], contactTagsItem: ContactTag[]): void {
    this.store.dispatch(new LoadContactTags());
    this.contactService.getContactTags().subscribe(contactTags => {
      this.store.dispatch(new LoadContactTagsSuccess(contactTags));
      let changes: any = [];
      let allContactTags: ContactTag[] = [];
      this.store.select(getContactTags).pipe(take(1)).subscribe( contactTagResponse => {
        contactTagsItem.map(ct => {
          const contactTag = contactTagResponse.filter(c => c.name.toString() === ct.name.toString())[0];
          if (!!contactTag) {
            allContactTags.push(contactTag);
          }
        });
      });
      contacts.forEach(contact => {
        if (!!contact.tags && contact.tags.length === 0) {
          contact.tags = allContactTags;
        } else {
          if (!!contact.tags && contact.tags.length > 0) {
            allContactTags.map(cl => {
              const tag = contact.tags.filter(contactTag => contactTag.name.toString() === cl.name.toString())[0];
              if (tag === undefined) {
                contact.tags.push(cl);
              }
            });
          }
        }
        changes.push({id: contact.id, changes: contact });
      });
      const type = this.getCurrentRouteType();
      if (type === AppConstants.LIST) {
        this.store.dispatch(new UpdateMultipleListContactSuccess(changes));
      } else if (type === AppConstants.TAG) {
        this.store.dispatch(new UpdateMultipleTagContactSuccess(changes));
      } else {
        this.store.dispatch(new UpdateMultipleContactSuccess(changes));
      }
      this.toastService.show("CONTACT_UPDATED_MSG");
    }, err => {
      this.store.dispatch(new LoadContactTagsFail());
    });
  }

  markContactFavorite(markFavorite: boolean, contact: Contact[]) {
    console.log("markContactFavorite", markFavorite, contact );
    let contactGroupId: string = "";
    this.store.select(getFavoriteGroup).pipe(take(1)).subscribe(res => {
      contactGroupId = res.id;
    });
    const ids = contact.map(c => c.id);
    let changes: any = [];
    const type = this.getCurrentRouteType();
    if (markFavorite) {
      this.contactService.markAsFavorite(contactGroupId, ids.toString()).pipe(take(1)).subscribe(res => {
        contact.map(c => {
          c.favorite = true;
          changes.push({id: c.id, changes: c });
        });
        if (changes.length > 0) {
          if (type === AppConstants.LIST) {
            this.store.dispatch(new UpdateMultipleListContactSuccess(changes));
          } else if (type === AppConstants.TAG) {
            this.store.dispatch(new UpdateMultipleTagContactSuccess(changes));
          } else {
            this.store.dispatch(new UpdateMultipleContactSuccess(changes));
          }
        }
        this.translate.get("ADDED_TO_FAVORITES").subscribe(msg => {
          this._snackBar.openFromComponent(SnackBarNotificationComponent, {
            duration: 2000,
            panelClass: "snackbar-notificiation",
            horizontalPosition : "left",
            verticalPosition : "bottom",
            data: {
              message: msg,
            },
          });
        });
        this.broadcaster.broadcast(BroadcastKeys.FAVORITE_UNFAVORITE_CONTACT_RESPONSE, markFavorite);
      }, error => {
        this.toastService.showPlainMessage(error);
      });
    } else {
      this.contactService.markAsNotFavorite(contactGroupId, ids.toString()).pipe(take(1)).subscribe(res => {
        contact.map(c => {
          c.favorite = false;
          changes.push({id: c.id, changes: c });
        });
        if (changes.length > 0) {
          if (type === AppConstants.LIST) {
            this.store.dispatch(new UpdateMultipleListContactSuccess(changes));
          } else if (type === AppConstants.TAG) {
            this.store.dispatch(new UpdateMultipleTagContactSuccess(changes));
          } else {
            this.store.dispatch(new UpdateMultipleContactSuccess(changes));
          }
        }
        this.broadcaster.broadcast(BroadcastKeys.FAVORITE_UNFAVORITE_CONTACT_RESPONSE, markFavorite);
        this.translate.get("REMOVED_FROM_FAVORITES").subscribe(msg => {
          this._snackBar.openFromComponent(SnackBarNotificationComponent, {
            duration: 2000,
            panelClass: "snackbar-notificiation",
            horizontalPosition : "left",
            verticalPosition : "bottom",
            data: {
              message: msg,
            },
          });
        });
      }, error => {
        this.toastService.showPlainMessage(error);
      });
    }
  }

  getFavoriteGroup(): void {
    this.contactService.getFavoriteGroup().pipe(take(1)).subscribe(res => {
      if (res.contact_groups && res.contact_groups.length === 0) {
        this.contactService.createFavoriteContctGroup().pipe(take(1)).subscribe(res => {
          console.log("[createFavoriteContctGroup]", res.contact_group);
          this.store.dispatch(new SetFavoriteGroup(res.contact_group));
        }, error => {
          this.toastService.showPlainMessage(error);
        });
      } else {
        if (res.contact_groups && res.contact_groups.length > 0) {
          this.store.dispatch(new SetFavoriteGroup(res.contact_groups[0]));
        }
      }
    }, error => {
      this.toastService.showPlainMessage(error);
    });
  }

  getLoggedInUserData(): void {
    this.contactService.getLoggedInUserContact().pipe(take(1)).subscribe(res => {
      console.log("[getLoggedInUserData]", res);
      if (!!res && res.contact) {
        const contact = ContactUtils.getContacts(res.contact);
        console.log("[getLoggedInUserData][contact]" ,  contact);
        this.store.dispatch(new SetLoggedInUserContact(contact));
      }
    }, error => {
      console.log("[getUserProfile][Error]" , error);
    });
  }

  updateLoggedInUserContact(contact: any): void {
    this.contactService.updateLoggedInUserContactDetail(contact).pipe(take(1)).subscribe(res => {
      if (!!res && res.contact) {
        const newContact = this.mapContacts([res.contact]);
        console.log("[updateLoggedInUserContact][response]: ", newContact[0]);
        this.store.dispatch(new SetLoggedInUserContact(newContact[0]));
        this.broadcaster.broadcast(BroadcastKeys.SUCCESS_CREATE_UDATE_CONTACT_REQUEST);
        if (contact.favorite) {
          this.markContactFavorite(contact.favorite, [res.contact]);
        }
      }
    }, error => {
      this.toastService.showPlainMessage(error);
      this.broadcaster.broadcast(BroadcastKeys.CREATE_UPDATE_REQUEST_FAIL);
    });
  }

  createTask(contact: Contact[]): void {
    if ( contact && contact.length === 1) {
      let taskAppURL: string = "";
      this.store.select(getFederatedApps).pipe(take(1)).subscribe(apps => {
        taskAppURL = apps.filter( ap => ap.name === "vnctask")[0].options.url;
      });
      taskAppURL = this.getURL(taskAppURL);
      console.log("createTask taskAppURL: ", taskAppURL);
      const mail = !!contact[0].jid ? contact[0].jid : (!!contact[0].emails ? contact[0].emails[0]?.email : "");
      const email = !!contact[0].jid ? contact[0].jid : mail;
      const param = !!contact[0].username ? contact[0].username : email;
      if (!param) {
        this.toastService.show("INSUFFICIENT_CONTACT_INFO_TASK_PROJECT");
        return;
      }
      const sendURL = taskAppURL + "task/open?action=compose&assignee=" + param;
      console.log("createTask sendURL: ", sendURL);
      let nativeLink = "vnctask://" + sendURL.split("task/")[1];
      if (environment.isCordova) {
        this.contactService.checkInstalledApp("vnctask://", "vnctask").pipe(take(1)).subscribe(installed => {
          console.log("createTask checkinstalled: ", installed);
          if (installed) {
            if (device.platform === "iOS") {
              window.open(nativeLink, "_system");
            } else if (device.platform === "Android") {
              navigator.app.loadUrl(nativeLink, {
                openExternal: true
              });
            }
          } else {
            const url = CommonUtil.getAppStoreOrWeblink({ name: "vnctask" });
            if (device.platform === "iOS") {
              window.open(url, "_system");
            } else if (device.platform === "Android") {
              navigator.app.loadUrl(url, {
                openExternal: true
              });
            }
          }
        });

      } else if (environment.isElectron) {
        try {
          let nativeHandler = this.electronService.app.getApplicationNameForProtocol("vnctask://");
          console.log("[contacts.repo] nativeHandler: ", nativeHandler);
          if (nativeHandler && nativeHandler !== "") {
            this.electronService.openExternalUrl(nativeLink);
          } else {
            this.electronService.openExternalUrl(CommonUtil.translateAppURLtoDeeplinkURL(sendURL));
          }
        } catch (e) {
          console.error("[contactRepository] error: ", e);
        }
      } else {
        window.open(sendURL, "_blank");
      }
    }
  }

  createTicket(contact: Contact[]): void {
    if ( contact && contact.length === 1) {
      let projectAppURL: string = "";
      this.store.select(getFederatedApps).pipe(take(1)).subscribe(apps => {
        projectAppURL = apps.filter( ap => ap.name === "vncproject")[0].options.url;
      });
      projectAppURL = this.getURL(projectAppURL);
      const mail = !!contact[0].jid ? contact[0].jid : (!!contact[0].emails ? contact[0].emails[0]?.email : "");
      const email = !!contact[0].jid ? contact[0].jid : mail;
      const param = !!contact[0].username ? contact[0].username : email;
      if (!param) {
        this.toastService.show("INSUFFICIENT_CONTACT_INFO_TASK_PROJECT");
        return;
      }

      const sendURL = projectAppURL + "issues/new?assignee_username=" + param;
      if (environment.isCordova) {
        if (device.platform === "iOS") {
          window.open(sendURL, "_system");
        } else if (device.platform === "Android") {
          navigator.app.loadUrl(sendURL, {
            openExternal: true
          });
        }
      } else if (environment.isElectron) {
        this.electronService.openExternalUrl(sendURL);
      } else {
        window.open(sendURL, "_blank");
      }
    }
  }

  sendEmail(contact: Contact[]): void {
      const emails: any[] = [];
      contact.map( c => {
        if (!!c.emails && c.emails !== null && c.emails.length > 0) {
          emails.push(c.emails[0].email);
        }
      });
      if (emails.length > 10) {
        this.toastService.show("SEND_EMAIL_LIMIT_ERROR_MSG");
      } else if (emails.length === 0) {
        this.toastService.show("CONTACT_MUST_HAVE_EMAIL_ADDRESS");
      } else {
        let mailAppURL: string = "";
        this.store.select(getFederatedApps).pipe(take(1)).subscribe(apps => {
          mailAppURL = apps.filter( ap => ap.name === "vncmail")[0].options.url;
        });
        mailAppURL = this.getURL(mailAppURL);
        const sendURL = mailAppURL + "mail/compose?to=" + emails.toString();
        const nativeURL = "vncmail://compose?to=" + emails.toString();
        console.log("USING NATIVE URL: ", nativeURL);
        if (environment.isCordova) {
          this.contactService.checkInstalledApp("vncmail://", "vncmail").pipe(take(1)).subscribe(installed => {
            console.log("sendEmail checkinstalled: ", installed);
            if (installed) {
              if (device.platform === "iOS") {
                window.open(nativeURL, "_system");
              } else if (device.platform === "Android") {
                navigator.app.loadUrl(nativeURL, {
                  openExternal: true
                });
              }
            } else {
              const url = CommonUtil.getAppStoreOrWeblink({ name: "vncmail" });
              if (device.platform === "iOS") {
                window.open(url, "_system");
              } else if (device.platform === "Android") {
                navigator.app.loadUrl(url, {
                  openExternal: true
                });
              }
            }
          });

        } else if (environment.isElectron) {
          try {
            let nativeHandler = this.electronService.app.getApplicationNameForProtocol("vncmail://");
            console.log("[contacts.repo] nativeHandler: ", nativeHandler);
            if (nativeHandler && nativeHandler !== "") {
              let nativeLink = "vncmail://" + sendURL.split("mail/")[1];
              this.electronService.openExternalUrl(nativeLink);
            } else {
              this.electronService.openExternalUrl(CommonUtil.translateAppURLtoDeeplinkURL(sendURL));
            }
          } catch (e) {
            console.error("[contactRepository] error: ", e);
          }
        } else {
          window.open(sendURL, "_blank");
        }
      }
  }

  shareContacts(contacts: Contact[]): void {
    const ids = contacts.map(v => v.id);
    let mailAppURL: string = "";
    this.store.select(getFederatedApps).pipe(take(1)).subscribe(apps => {
      mailAppURL = apps.filter( ap => ap.name === "vncmail")[0].options.url;
    });
    mailAppURL = this.getURL(mailAppURL);
    const sendURL = mailAppURL + "mail/compose?contact_id=" + ids.toString();
    let nativeLink = "vncmail://" + sendURL.split("mail/")[1];
    if (environment.isCordova) {
      this.contactService.checkInstalledApp("vncmail://", "vncmail").pipe(take(1)).subscribe(installed => {
        console.log("sendEmail checkinstalled: ", installed);
        if (installed) {
          if (device.platform === "iOS") {
            window.open(nativeLink, "_system");
          } else if (device.platform === "Android") {
            navigator.app.loadUrl(nativeLink, {
              openExternal: true
            });
          }
        } else {
          const url = CommonUtil.getAppStoreOrWeblink({ name: "vncmail" });
          if (device.platform === "iOS") {
            window.open(url, "_system");
          } else if (device.platform === "Android") {
            navigator.app.loadUrl(url, {
              openExternal: true
            });
          }
        }
      });
    } else if (environment.isElectron) {
      try {
        let nativeHandler = this.electronService.app.getApplicationNameForProtocol("vncmail://");
        if (nativeHandler && nativeHandler !== "") {
          let nativeLink = "vncmail://" + sendURL.split("mail/")[1];
          this.electronService.openExternalUrl(nativeLink);
        } else {
          this.electronService.openExternalUrl(CommonUtil.translateAppURLtoDeeplinkURL(sendURL));
        }
      } catch (e) {
        console.error("[contactRepository] error: ", e);
      }
    } else {
      window.open(sendURL, "_blank");
    }
  }

  makeTalkAudioChatVideoOperation(contact: Contact[], action: string, groupAction: string): void {
    const emails: any[] = [];
    contact.map( c => {
      if (c.jid && c.jid !== null && c.jid !== "") {
        emails.push(c.jid);
      } else if (!!c.emails && c.emails !== null && c.emails.length > 0) {
        emails.push(c.emails[0].email);
      }
    });
    if (emails.length > 10) {
      this.toastService.show("SEND_EMAIL_LIMIT_ERROR_MSG");
    } else if (emails.length === 0) {
      this.toastService.show("CONTACT_MUST_HAVE_EMAIL_ADDRESS");
    } else {
      if (emails.length === 1) {
        this.talkCallAudioChatVideoOperation(action, emails.toString());
      } else {
        const dialog = this.matDialog.open(CreateActionGroupDialogComponent, {
          width: "325px",
          height: "212px",
          autoFocus: true,
          panelClass: "create-tag-dialog"
        });
        dialog.afterClosed().pipe(take(1)).subscribe(res => {
          if (!!res && res.group && res.group !== "") {
            const groupName = res.group;
            this.talkCallAudioChatVideoOperation(groupAction, emails.toString(), groupName);
          }
        });
      }
    }
  }

  talkCallAudioChatVideoOperation(action: string, target: string, groupName?: string) {
    let talkAppURL: string = "";
    this.store.select(getFederatedApps).pipe(take(1)).subscribe(apps => {
      talkAppURL = apps.filter( ap => ap.name === "vnctalk")[0].options.url;
    });
    talkAppURL = this.getURL(talkAppURL);
    let sendURL = talkAppURL + "talk/trigger?action=" + action + "&target=" + target;
    if (groupName) {
      sendURL += "&groupName=" + groupName;
    }
    let nativeLink2 = "vnctalk://main/" + sendURL.split(talkAppURL)[1];

    if (environment.isCordova) {
      this.contactService.checkInstalledApp("vnctalk://", "vnctalk").pipe(take(1)).subscribe(installed => {
        console.log("talkCallAudioChatVideoOperation checkinstalled: ", installed);
        if (installed) {
          if (device.platform === "iOS") {
            window.open(nativeLink2, "_system");
          } else if (device.platform === "Android") {
            navigator.app.loadUrl(nativeLink2, {
              openExternal: true
            });
          }
        } else {
          const url = CommonUtil.getAppStoreOrWeblink({name: "vnctalk"});
          if (device.platform === "iOS") {
            window.open(url, "_system");
          } else if (device.platform === "Android") {
            navigator.app.loadUrl(url, {
              openExternal: true
            });
          }
        }
      });
    } else if (environment.isElectron) {
      try {
        let nativeHandler = this.electronService.app.getApplicationNameForProtocol("vnctalk://");
        console.log("[contacts.repo] nativeHandler: ", nativeHandler);
        if (nativeHandler && nativeHandler !== "") {
          let nativeLink = "vnctalk://main/" + sendURL.split(talkAppURL)[1];
          console.log("[contacts.repo] nativeHandler open: ", nativeLink);
          this.electronService.openExternalUrl(nativeLink);
        } else {
          this.electronService.openExternalUrl(CommonUtil.translateAppURLtoDeeplinkURL(sendURL));
        }
      } catch (e) {
        console.error("[contactRepository] error: ", e);
      }
    } else {
      window.open(sendURL, "_blank");
    }
  }

  getURL(url: string): string {
    let sendURL: string = "";
    if (url.endsWith("/")) {
      sendURL = url;
    } else {
      sendURL = url + "/";
    }
    return sendURL;
  }

  changeTagColor(tagId: string , color: string): void {
    this.contactService.updateTagColor(tagId, color).pipe(take(1)).subscribe(res => {
      if (!!res && res.tag) {
        const tagItem = res.tag;
        let changes: any = [];
        /* Update Contact Tag in contact */
        this.store.select(getContacts).pipe(take(1)).subscribe((contacts: Contact[]) => {
          if (!!contacts && contacts.length > 0) {
            contacts.map(c => {
              if (c.tags && c.tags.length > 0) {
                c.tags.map(tg => {
                  if (tg.name === tagItem.name) {
                    if (tagItem.color_hex) {
                      tg.color_hex = tagItem.color_hex;
                      changes.push({id: c.id, changes: c });
                    }
                  }
                });
              }
            });
          }
        });
        /* Update contact store after contact tag update in contact */
        if (changes.length) {
          this.store.dispatch(new UpdateMultipleTagContactSuccess(changes));
        }
        this.getContactTags();
      }
    }, err => {
      this.toastService.showPlainMessage(err);
    });
  }

  getStatistics(): void {
    console.log("[contact.repo getStatistics]: ");
    if (this.isOnline) {
      console.log("contact.repo getStatistics calling getAllDirectoryContactGroup");
      this.contactService.getAllDirectoryContactGroup(0, this.limit).pipe(take(1)).subscribe(cont => {
        const contactGroupCount = this.removeFavouriteAndHiddenRemoveListGroup(cont.contact_groups);
        this.contactService.getStatistics().pipe(take(1)).subscribe(res => {
          console.log("[getStatistics]: ", res);
          if (!!res) {
            res.contact_groups_count = contactGroupCount;
            this.store.dispatch(new SetStatistics(res));
          }
        }, error => {
          this.toastService.showPlainMessage(error);
        });
      });
    } else {
      if (this.databaseService.dbReady.value) {
        this.databaseService.getAllContactGroup().subscribe(groups => {
          const contactGroupCount = this.removeFavouriteAndHiddenRemoveListGroup(groups);
          // toDo: stats from db
        });
      } else {
        setTimeout(() => {
          this.getAllContactGroup();
        }, 200);
      }

    }
  }

  removeFavouriteAndHiddenRemoveListGroup(contactList) {
    const withRemoveFavoriteAndHiddenRemovedTalk: Contact[] = [];
    contactList.map(wContact => {
      if ((wContact.name !== AppConstants.FAVOURITE_LIST_NAME) && (wContact.name !== AppConstants.HIDDEN_REMOVED_LIST_NAME)) {
        withRemoveFavoriteAndHiddenRemovedTalk.push(wContact);
      }
    });
    return withRemoveFavoriteAndHiddenRemovedTalk.length;
  }

  searchDocs(params): Observable<SearchResponse> {
    if (params.type_s && params.type_s === "*") {
      const response = new Subject<any>();
      const alldata: any [] = [];
      const talkParamas = _.clone(params, true);
      alldata.push(this.contactService.searchDocs(params));
      talkParamas.allTalk = "true";
      alldata.push(this.contactService.searchDocs(talkParamas));
      forkJoin(alldata).pipe(take(1)).subscribe(res => {
        if (!!res && res.length > 0) {
          let docs = [];
          let numFound = 0;
          let start = 0;
          let groups = {};
          res.map((i: any) => {
            if (i.docs) {
              i.docs.map((doItem: any) => {
                docs.push(doItem);
              });
            }
            numFound += i.numFound;
          });
          const data = { docs:  _.orderBy(docs, "createdDt", "desc"), numFound: numFound, start: start, groups: groups } as SearchResponse;
          response.next(data);
        }
      });
      return response.asObservable();
    } else {
      return this.contactService.searchDocs(params);
    }
  }

  getTrashContacts(query = "", sort = "asc"): void {
    this.trashSort = sort;
    this.store.dispatch(new LoadTrashContacts());
    let offset = 0;
    this.contactService.getTrashContacts(offset, this.limit, sort).pipe(take(1)).subscribe(res => {
      if (!!res && res !== null && res.contacts) {
        let contacts: Contact[] = [];
        contacts = this.mapContacts(res.contacts);
        let isMoreContacts = false;
        offset = contacts.length;
        if (contacts.length === this.limit) {
          isMoreContacts = true;
        } else {
          offset = 0;
          isMoreContacts = false;
        }
        this.store.dispatch(new LoadTrashContactsSuccess({ currOffset: offset, isMoreContacts: isMoreContacts, contact: contacts }));
      }
    }, error => {
      this.store.dispatch(new LoadTrashContactsFail());
    });
  }

  public loadMoreTrashContacts() {
    let nextPageLoading: boolean;
    let offset = 0;
    let isMoreContacts = false;
    this.store.select(getTrashContactsCurrentPageOffset).pipe(take(1)).subscribe(o => offset = o);
    this.store.select(getIsMoreTrashContacts).pipe(take(1)).subscribe(l => isMoreContacts = l);
    if (nextPageLoading) {
      return;
    }
    if (!isMoreContacts) {
      return;
    }
    this.store.dispatch(new NextLoadTrashContacts());
    this.contactService.getTrashContacts(offset, this.limit, this.trashSort).subscribe(res => {
      if (!!res && res !== null && res.contacts) {
        let contacts: Contact[] = [];
        contacts = this.mapContacts(res.contacts);
        let newOffset = offset + contacts.length;
        if (contacts.length === this.limit) {
          isMoreContacts = true;
        }
        else {
          newOffset = 0;
          isMoreContacts = false;
        }
        this.store.dispatch(new NextLoadTrashContactsSuccess({
          currOffset: newOffset,
          isMoreContacts: isMoreContacts,
          contacts: contacts as Contact[]
        }));
      }
    }, err => {
      if (err === undefined) {
        this.store.dispatch(new NextLoadTrashContactsFail());
        this.toastService.show("NETWORK_CONNECTION_LOST");
        return;
      }
      this.store.dispatch(new NextLoadTrashContactsFail());
      this.toastService.showPlainMessage(err);
    });
  }

  deleteContactPermanent(contacts: Contact[]): void {
    console.log("[deleteContactPermanent]:", contacts);
    const ids = contacts.map(c => c.id);
    this.contactService.deleteContactPermanently(ids.toString()).pipe(take(1)).subscribe(res => {
      if (!!res && res.success) {
        this.store.dispatch(new RemoveMultipleTrashContactSuccess(ids));
        this.toastService.show("CONTACT_DELETE_SUCCESSFULLY");
        this.broadcaster.broadcast("RESET_CONTACT_PLUS_LIST");
        this.broadcaster.broadcast(BroadcastKeys.RESET_CONTACT_LIST);
        this.getStatistics();
      }
    }, error => {
      this.toastService.showPlainMessage(error);
    });
  }

  recoverContactFromTrash(contacts: Contact[]): void {
    console.log("[recoverContactFromTrash]:", contacts);
    const ids = contacts.map(c => c.id);
    this.contactService.recoverContactFromTrash(ids).pipe(take(1)).subscribe(res => {
      this.store.dispatch(new RemoveMultipleTrashContactSuccess(ids));
      this.toastService.show("CONTACT_RECOVER_SUCCESSFULLY");
      this.broadcaster.broadcast("RESET_CONTACT_PLUS_LIST");
      this.broadcaster.broadcast(BroadcastKeys.RESET_CONTACT_LIST);
      this.getStatistics();
    }, error => {
      this.toastService.showPlainMessage(error);
    });
  }

  callPhoneNumber(phoneNumber: string): void {
    const callNumber = "tel: " + phoneNumber + "";
    this.openLinkFromHref(callNumber);
  }

  composeOnEmail(email: string): void {
    const callNumber = "mailto: " + email + "";
    this.openLinkFromHref(callNumber);
  }

  openAddressOnMap(address: string): void {
    let addressItem: string = "";
    if (environment.isCordova) {
      addressItem = "geo:0,0?q=" + address;
    } else {
      addressItem = "https://www.google.com/maps?q=" + address;
    }
    this.openLinkFromHref(addressItem);
  }

  openLinkFromHref(href: string): void {
    const a = document.createElement("a");
    a.classList.add("open-new-window");
    a.href = href;
    a.target = "_blank";
    console.log("[openLinkFromHref]", a);
    a.click();
    a.remove();
  }

  openAdvanceSearchDialog(): void {
    this.matDialog.open(AdvanceSearchDialogComponent, {
      maxWidth: "100%",
      autoFocus: false,
      panelClass: "contact_plus_advance_search_dialog"
    });
  }

  advanceSearchFromList(searchList: ContactFolder[], searchTagItem: ContactTag[], query: string): void {
    setTimeout(() => {
      this.store.dispatch(new LoadContacts());
    }, 0);
    let offset = 0;
    let queryItem: string = "all=1&q=" + query;
    if (!!searchList && searchList.length > 0) {
      const ids = searchList.map(f => f.id);
      const listIds = ids.join("|");
      queryItem += "&contact_list=" + listIds;
    }
    if (!!searchTagItem && searchTagItem.length > 0) {
      const ids = searchTagItem.map(f => f.id);
      const listIds = ids.join("|");
      queryItem += "&tags=" + listIds;
    }
    this.contactService.advanceSearchFromList(queryItem).pipe(take(11)).subscribe(res => {
      if (!!res && res !== null && res.contacts) {
        let contacts: Contact[] = [];
        contacts = this.mapContacts(res.contacts);
        const isMoreContacts = false;
        offset = contacts.length;
        this.store.dispatch(new LoadContactsSuccess({ currOffset: offset, isMoreContacts: isMoreContacts, contact: contacts }));
      }
    }, error => {
      this.toastService.showPlainMessage(error);
      this.store.dispatch(new LoadContactsFail());
    });
  }

  commingSoonToastMessage(): void {
    this.toastService.show("COMING_SOON_LBL");
  }

  shareContactVcard(contacts: Contact[]): void {
    const vcardData = ContactUtils.getVCFData(contacts);
    this.contactService.shareVCFToMobile(vcardData);
  }

  saveSearch(): void {
    let searchQuery: string = "";
    let searchListItem: ContactFolder[] = [];
    let searchTagItem: ContactTag[] = [];
    this.store.select(getSearchQuery).pipe(take(1)).subscribe(query => {
      searchQuery = query;
    });
    this.store.select(getSearchInLists).pipe(take(1)).subscribe(res => {
        searchListItem = res;
    });
    this.store.select(getSearchInTags).pipe(take(1)).subscribe(res => {
        searchTagItem = res;
    });
    const sendQuery: any = {};
    const f: any[] = [];
    const op: any = {};
    const v: any = {};

    sendQuery.type = "ContactQuery";
    sendQuery.commit = "Save";

    f.push("q");
    op.q = "~";
    v.q = [searchQuery];

    sendQuery.f = f;
    sendQuery.op = op;
    sendQuery.v = v;

    const dialog = this.matDialog.open(SaveSearchNameDialogComponent, {
      width: "325px",
      height: "212px",
      autoFocus: true,
      panelClass: "create-tag-dialog"
    });
    dialog.afterClosed().pipe(take(1)).subscribe(res => {
      if (!!res && res.saveName && res.saveName !== "") {
        const saveName = res.saveName;
        sendQuery.query = {
          name: saveName
        };
        this.contactService.saveSearchQuery(sendQuery).pipe(take(1)).subscribe(res => {
          console.log("[Save Search Response]" , res);
          this.toastService.show("SEARCH_HAS_BEEN_SAVE");
          this.getSaveSearchQuery();
        }, error => {
          console.log("[Error]: ", error);
          this.toastService.showPlainMessage(error);
        });
      }
    });
  }

  getSaveSearchQuery(): void {
    this.contactService.getSaveSearch().pipe(take(1)).subscribe(res => {
      if (!!res && res.length > 0) {
        this.store.dispatch(new SetSaveSearch(res));
      }
    } , error => {
      this.toastService.showPlainMessage(error);
    });
  }

  searchFromSaveSearch(search: any): void {
    if (!!search && search.filters) {
      const filters = search.filters;
      let searchString: any[] = [];
      console.log("[searchFromSaveSearch][filters]: ", filters);
      if (Object.keys(filters).length === 0) {
        this.toastService.show("SEARCH_NOT_HAVE_VALUE_MESSAGE");
        return;
      }
      let searchText: string = "";
      Object.keys(filters).forEach(key => {
          searchString.push(key + "=" + filters[key].operator + filters[key].values.join(","));
          if (key === "q") {
            searchText = filters[key].values[0];
          }
      });
      this.router.navigate(["/contactplus", "search"], { queryParams: { searchText: searchText, searchType: "contact", saveSearch: true, query: searchString.join("&") } });
    }
  }

  advanceSearchFromSaveSearch(list: string, tag: string, query: string): void {
    setTimeout(() => {
      this.store.dispatch(new LoadContacts());
    }, 0);
    let offset = 0;
    let queryItem: string = "all=1&q=" + query;
    if (!!list && list !== "") {
      queryItem += "&contact_list=" + list.split(",").join("|");
    }
    if (!!tag && tag !== "") {
      queryItem += "&tags=" + tag.split(",").join("|");
    }
    console.log("[advanceSearchFromSaveSearch]: ", queryItem);
    this.contactService.advanceSearchFromList(queryItem).pipe(take(11)).subscribe(res => {
      if (!!res && res !== null && res.contacts) {
        let contacts: Contact[] = [];
        contacts = this.mapContacts(res.contacts);
        const isMoreContacts = false;
        offset = contacts.length;
        this.store.dispatch(new LoadContactsSuccess({ currOffset: offset, isMoreContacts: isMoreContacts, contact: contacts }));
      }
    }, error => {
      this.toastService.showPlainMessage(error);
      this.store.dispatch(new LoadContactsFail());
    });
  }

  removeAllContactPermanent(): void {
    const dlg = this.matDialog.open(ConfirmationDialogComponent, {
      maxWidth: "100%",
      autoFocus: false,
      panelClass: "confirm_contactplus_dialog",
      data : { dialogType: ConfirmDialogType.DELETE_CONTACT, message_title: "DELETE_FROM_TRASH", message_body: "DELETE_ALL_CONTACT"}
    });
    dlg.afterClosed().pipe(take(1)).subscribe(res => {
        if (!!res && res.confirmation) {
            if (res.confirmation === "yes") {
              this.removeAllContactsPermanent();
            }
        }
    });
  }

  removeAllContactsPermanent(): void {
    this.contactService.removeAllContactPermanent().pipe(take(1)).subscribe(res => {
      this.store.dispatch(new LoadTrashContactsSuccess({ currOffset: 0, isMoreContacts: false, contact: [] }));
      this.toastService.show("CONTACT_DELETE_SUCCESSFULLY");
      this.broadcaster.broadcast("RESET_CONTACT_PLUS_LIST");
      this.broadcaster.broadcast(BroadcastKeys.RESET_CONTACT_LIST);
      this.getStatistics();
    }, error => {
      this.toastService.showPlainMessage(error);
    });
  }

  recoverAllContact(): void {
    this.contactService.recoverAllContactFromTrash().pipe(take(1)).subscribe(res => {
      this.store.dispatch(new LoadTrashContactsSuccess({ currOffset: 0, isMoreContacts: false, contact: [] }));
      this.toastService.show("CONTACT_RECOVER_SUCCESSFULLY");
      this.broadcaster.broadcast("RESET_CONTACT_PLUS_LIST");
      this.broadcaster.broadcast(BroadcastKeys.RESET_CONTACT_LIST);
      this.getStatistics();
    }, error => {
      this.toastService.showPlainMessage(error);
    });
  }

  getCurrentRouteId(): string {
    let type = AppConstants.CONTACTS;
    this.store.select(getRouteId).pipe(take(1)).subscribe(routeID => {
      type = routeID;
    });
    return type;
  }

  getCurrentRouteType(): string {
    let type = AppConstants.CONTACTS;
    this.store.select(getRouteType).pipe(take(1)).subscribe(routeType => {
      type = routeType;
    });
    return type;
  }

  setRouteTypeBasedonURL(): void {
    const url = this.getCurrentURL();
    console.log("[setRouteTypeBasedonURL]: ", url);
    if (url === "/contactplus/frequently_contacted") {
      this.store.dispatch(new SetRoutingType(AppConstants.CONTACTS));
      this.store.dispatch(new SetRouteId(AppConstants.FREQUENTLY_CONTACTED));
    } else if (url === "/contactplus/duplicate_contact") {
      this.store.dispatch(new SetRoutingType(AppConstants.CONTACTS));
      this.store.dispatch(new SetRouteId(AppConstants.DUPLICATE_CONTACT));
    } else if (url === "/contactplus/trash") {
      this.store.dispatch(new SetRoutingType(AppConstants.CONTACTS));
      this.store.dispatch(new SetRouteId(AppConstants.TRASH_CONTACT));
    } else {
      this.store.dispatch(new SetRoutingType(AppConstants.CONTACTS));
      this.store.dispatch(new SetRouteId(AppConstants.CONTACTS_ALL));
    }
    this.broadcaster.broadcast(BroadcastKeys.LOAD_CONTACTS_FROM_STORE);
  }

  updateGroupMember(contact: Contact): void {
    const routeId = this.getCurrentRouteId();
    if (routeId === AppConstants.CONTACT_GROUP) {
      this.broadcaster.broadcast(BroadcastKeys.UPDATE_CONTACT_GROUP_MEMBER, {
        contact: contact
      });
    }
  }

  deleteGroupContactMember(checkedContacts: Contact[]): void {
    const routeId = this.getCurrentRouteId();
    if (routeId === AppConstants.CONTACT_GROUP) {
      this.broadcaster.broadcast(BroadcastKeys.DELETE_CONTACT_GROUP_MEMBER, {
        contact: checkedContacts[0]
      });
    }
  }

  openTask(taskId: string): void {
    let taskAppURL: string = "";
    this.store.select(getFederatedApps).pipe(take(1)).subscribe(apps => {
      taskAppURL = apps.filter( ap => ap.name === "vnctask")[0].options.url;
    });
    taskAppURL = this.getURL(taskAppURL);
    const sendURL = taskAppURL + "redirect?action=open-task&id=" + taskId;
    if (environment.isCordova) {
      if (device.platform === "iOS") {
        window.open(sendURL, "_system");
      } else if (device.platform === "Android") {
        navigator.app.loadUrl(sendURL, {
          openExternal: true
        });
      }
    } else if (environment.isElectron) {
      this.electronService.openExternalUrl(sendURL);
    } else {
      window.open(sendURL, "_blank");
    }
  }

  openTicket(ticketId: string): void {
    let projectAppURL: string = "";
    this.store.select(getFederatedApps).pipe(take(1)).subscribe(apps => {
      projectAppURL = apps.filter( ap => ap.name === "vncproject")[0].options.url;
    });
    projectAppURL = this.getURL(projectAppURL);
    const sendURL = projectAppURL + "issues/" + ticketId;
    if (environment.isCordova) {
      if (device.platform === "iOS") {
        window.open(sendURL, "_system");
      } else if (device.platform === "Android") {
        navigator.app.loadUrl(sendURL, {
          openExternal: true
        });
      }
    } else if (environment.isElectron) {
      this.electronService.openExternalUrl(sendURL);
    } else {
      window.open(sendURL, "_blank");
    }
  }

  openMail(mailId: string): void {
    let mailAppURL: string = "";
    this.store.select(getFederatedApps).pipe(take(1)).subscribe(apps => {
      mailAppURL = apps.filter( ap => ap.name === "vncmail")[0].options.url;
    });
    mailAppURL = this.getURL(mailAppURL);
    const sendURL = mailAppURL + "mail/inbox/detail/-" + mailId;
    if (environment.isCordova) {
      if (device.platform === "iOS") {
        window.open(sendURL, "_system");
      } else if (device.platform === "Android") {
        navigator.app.loadUrl(sendURL, {
          openExternal: true
        });
      }
    } else if (environment.isElectron) {
      this.electronService.openExternalUrl(sendURL);
    } else {
      window.open(sendURL, "_blank");
    }
  }

  jumpToChat(contact: Contact, talkId: string, createdDt: string): void {
    let talkAppURL: string = "";
    let timestamp = new Date(createdDt).getTime();
    let target = "";
    if ( contact && contact.jid) {
      target = contact.jid;
    } else {
      if ( contact && contact.emails && contact.emails.length > 0) {
        target = contact.emails[0].email;
      } else {
        this.toastService.show("CONTACT_MUST_HAVE_EMAIL_ADDRESS");
        return;
      }
    }
    this.store.select(getFederatedApps).pipe(take(1)).subscribe(apps => {
      talkAppURL = apps.filter( ap => ap.name === "vnctalk")[0].options.url;
    });
    talkAppURL = this.getURL(talkAppURL);
    const sendURL = talkAppURL + "talk/trigger?action=jump-to-chat&target=" + target + "&chat_id=" + talkId + "&timestamp=" + timestamp;
    let nativeLink2 = "vnctalk://main/" + sendURL.split(talkAppURL)[1];
    if (environment.isCordova) {
      this.contactService.checkInstalledApp("vnctalk://", "vnctalk").pipe(take(1)).subscribe(installed => {
        console.log("talkCallAudioChatVideoOperation checkinstalled: ", installed);
        if (installed) {
          if (device.platform === "iOS") {
            window.open(nativeLink2, "_system");
          } else if (device.platform === "Android") {
            navigator.app.loadUrl(nativeLink2, {
              openExternal: true
            });
          }
        } else {
          const url = CommonUtil.getAppStoreOrWeblink({ name: "vnctalk" });
          if (device.platform === "iOS") {
            window.open(url, "_system");
          } else if (device.platform === "Android") {
            navigator.app.loadUrl(url, {
              openExternal: true
            });
          }
        }
      });

    } else if (environment.isElectron) {
      try {
        let nativeHandler = this.electronService.app.getApplicationNameForProtocol("vnctalk://");
        console.log("[contacts.repo] nativeHandler: ", nativeHandler);
        if (nativeHandler && nativeHandler !== "") {
          let nativeLink = "vnctalk://main/" + sendURL.split(talkAppURL)[1];
          console.log("[contacts.repo] nativeHandler open: ", nativeLink);
          this.electronService.openExternalUrl(nativeLink);
        } else {
          this.electronService.openExternalUrl(CommonUtil.translateAppURLtoDeeplinkURL(sendURL));
        }
      } catch (e) {
        console.error("[contactRepository] error: ", e);
      }
    } else {
      window.open(sendURL, "_blank");
    }
  }

  restrictOpenMail(item: any): boolean {
    let currentUserContact: Contact;
    let isOpenMail: boolean = true;
    this.store.select(getLoggedInUserContact).pipe(take(1)).subscribe(res => {
      if (!!res && res !== null) {
        currentUserContact = res;
      }
    });
    if (!!currentUserContact && currentUserContact !== null) {
      if (currentUserContact.jid && currentUserContact.jid === item.from) {
        isOpenMail = false;
      } else if (!!currentUserContact.emails && currentUserContact.emails.length > 0 && currentUserContact.emails[0].email === item.from) {
        isOpenMail = false;
      }
    }
    if (!isOpenMail) {
      this.toastService.show("EMAIL_OPEN_RESTRICT_MESSAGE");
    }
    return isOpenMail;
  }

  getContactFilterList(): void {
    this.contactService.getContactFilterList().pipe(take(1)).subscribe(res => {
      console.log("[getContactFilterList]", res);
      if (!!res && res.filters) {
        this.store.dispatch(new SetQueryFilters(res.filters));
      }
    }, error => {
      console.log("[Error][getContactFilterList]: ", error);
      this.toastService.showPlainMessage(error);
    });
  }

  saveAdvanceSearchQuery(saveQuery: string): Observable<any> {
    const response = new Subject<any>();
    this.contactService.saveSearchQuery(saveQuery).pipe(take(1)).subscribe(res => {
      console.log("[Save Search Response]" , res);
      response.next(res);
      this.getSaveSearchQuery();
    }, error => {
      console.log("[Error]: ", error);
      response.error(error);
      this.toastService.showPlainMessage(error);
    });
    return response.asObservable().pipe(take(1));
  }

  getSearchFromAdvanceSearchQuery(q: string): void {
    setTimeout(() => {
      this.store.dispatch(new LoadContacts());
    }, 0);
    let offset = 0;
    let contacts: Contact[] = [];
    let isMoreContacts = false;
    this.contactService.getSearchFromAdvanceSearchQuery(offset, this.limit, q).pipe(take(1)).subscribe(res => {
      console.log("[getSearchFromAdvanceSearchQuery]:", res);
      if (!!res && res !== null && res.contacts) {
        contacts = this.mapContacts(res.contacts);
        offset = contacts.length;
        if (contacts.length === this.limit) {
          isMoreContacts = true;
        } else {
          offset = 0;
          isMoreContacts = false;
        }
      }
      this.store.dispatch(new LoadContactsSuccess({ currOffset: offset, isMoreContacts: isMoreContacts, contact: contacts }));
    }, error => {
      this.toastService.showPlainMessage(error);
      this.store.dispatch(new LoadContactsFail());
    });
  }

  importContactFromBlob(blob: any, selectedList, importAddress:any, importsEventDates: any, importNotes: any , importURLs: any, customFields: any, importIMData :any): void {
    this.store.dispatch(new StartLoading());
    this.contactService.importContactFromBol(blob).pipe(take(1)).subscribe(res => {
      res.contacts.map(ct => {
        this.contactService.deleteContactGroup(ct.groups[0].id).subscribe(res => {
          this.broadcaster.broadcast("RESET_CONTACT_PLUS_LIST");
          this.store.dispatch(new RemoveMultipleGroupContactSuccess([ct.groups[0].id]));
          this.toastService.show("DELETE_GROUP_SUCCESS");
          this.broadcaster.broadcast(BroadcastKeys.RESET_CONTACT_LIST);
          this.getStatistics();
        });
      });
      res.contacts.map((contacts, index) => {
        this.submit(contacts, selectedList, importAddress[index], importsEventDates[index], importNotes[index], importURLs[index], customFields, importIMData[index]);
      });
      this.store.dispatch(new StopLoading());
      if (!!res && res !== null && res.contacts) {
        let contacts: Contact[] = [];
        contacts = this.mapContacts(res.contacts);
        const routeId = this.getCurrentRouteId();
        if (routeId === AppConstants.CONTACTS_ALL) {
          contacts.map(c => {
            this.store.dispatch(new CreateContactSuccess(c));
          });
        }
      }
      this.broadcaster.broadcast(BroadcastKeys.HIDE_IMPORT_CONTACT_DIALOG);
      this.toastService.show("CONTACT_IMPORT_SUCCESS_MSG");
      this.getStatistics();
    }, error => {
      console.log("[Error]: ", error);
      this.store.dispatch(new StopLoading());
      this.toastService.showPlainMessage(error);
    });
  }

  printContacts(contact: Contact[]): void {
    let translations: any;
    this.translate.get([
      "COMPANY",
      "JOB_TITLE",
      "ADDRESS",
      "PHONE_MOBILE_FAX",
      "EMAILS",
      "WEBSITE",
      "EVENTS",
      "TIME_ZONE",
      "LANGUAGE_LABEL",
      "SKILLS",
      "INTERESTS",
      "TAGS",
      "LISTS_LBL",
      "NOTES",
      "DATE_OF_BIRTH",
      "GENDER",
      "MARITAL_STATUS",
      "PRIVATE_EMAIL",
      "ENGAGEMENT_TYPE",
      "VNC_EMPLOYEE",
      "NOT_VNC_EMPLOYEE",
      "START_DATE",
      "END_DATE",
      "PER_WEEK_AVAILABLILITY",
      "HOURLY_RATE",
      "PAYMENT_MODE",
      "IM_ADDRESS",
      "PASSPORT_NUMBER",
      "PASSPORT_EXPIRY",
      "NATIONAL_ID_NUMBER",
      "NATIONAL_ID_EXPIRY",
      "USER_NAME",
      "ROLE_LBL",
      "ADMIN",
      "USER",
      "ACCEPTED_TERMS",
      "AGB_FLAG",
      "NOT_AGB_FLAG",
      "RFC_LIMIT",
      "VIDEO_BRIDGE",
      "ALLOW",
      "NOT_ALLOW",
      "SECURITY_ENCRYPTION",
      "OMEMO",
      "NOT_OMEMO",
      "PRODUCTS"
    ]).pipe(take(1)).subscribe(res => {
      translations = res;
    });
    let renderContact: string = "";
    renderContact += "<div style='text-align:center;'>VNCcontacts+</div>";
    contact.map((c: Contact) => {
      renderContact += "<div style='font-size: 30px;margin: 0 0 15px 0;'>" + c.fullName + "</div>";
      if (c.company) {
        renderContact += "<div style='display:flex;align-item: center;'>";
        renderContact += "<div style='width:35%;'>" + translations.COMPANY + "</div>";
        renderContact += "<div>";
        renderContact += "<div style='margin: 0 0 10px 0;'><span>" + c.company + "</span></div>";
        renderContact += "</div>";
        renderContact += "</div>";
      }
      if (c.jobTitle) {
        renderContact += "<div style='display:flex;align-item: center;'>";
        renderContact += "<div style='width:35%;'>" + translations.JOB_TITLE + "</div>";
        renderContact += "<div>";
        renderContact += "<div style='margin: 0 0 10px 0;'><span>" + c.jobTitle + "</span></div>";
        renderContact += "</div>";
        renderContact += "</div>";
      }
      if (c.emails && c.emails.length > 0) {
        renderContact += "<div style='display:flex;align-item: center;'>";
        renderContact += "<div style='width:35%;'>" + translations.EMAILS + "</div>";
        renderContact += "<div>";
        c.emails.map(e => {
          renderContact += "<div style='margin: 0 0 10px 0;'><span>" + e.email + "</span></div>";
        });
        renderContact += "</div>";
        renderContact += "</div>";
      }
      if (c.phones && c.phones.length > 0) {
        renderContact += "<div style='display:flex;align-item: center;'>";
        renderContact += "<div style='width:35%;'>" + translations.PHONE_MOBILE_FAX + "</div>";
        renderContact += "<div>";
        c.phones.map(p => {
          renderContact += "<div style='margin: 0 0 10px 0;'><span style='font-size:14px;'>" + p.phone + "</span>" +
          "<span style='height: 3px;width: 3px;background-color: #888888;border-radius: 50%;" +
          "display: inline-block;margin: 0 7px 3px 6px;'>" + "</span><span style='color:#888888;font-size:12px;'>" +
          p.phone_type + "</span></div>";
        });
        renderContact += "</div>";
        renderContact += "</div>";
      }
      if (c.address && c.address.length > 0) {
        renderContact += "<div style='display:flex;align-item: center;'>";
        renderContact += "<div style='width:35%;'>" + translations.ADDRESS + "</div>";
        renderContact += "<div>";
        c.address.map(a => {
          renderContact += "<div style='margin: 0 0 10px 0;'><span style='font-size:14px;'>" + a.street1 + ", " + a.city + ", " +
          a.state + ", " + a.country_code + " - " + a.postcode  + "</span>" +
          "<span style='height: 3px;width: 3px;background-color: #888888;border-radius: 50%;" +
          "display: inline-block;margin: 0 7px 3px 6px;'>" + "</span><span style='color:#888888;font-size:12px;'>" +
          a.address_type + "</span></div>";
        });
        renderContact += "</div>";
        renderContact += "</div>";
      }
      /* Url */
      if (c.urls && c.urls.length > 0) {
        renderContact += "<div style='display:flex;align-item: center;'>";
        renderContact += "<div style='width:35%;'>" + translations.WEBSITE + "</div>";
        renderContact += "<div>";
        c.urls.map(p => {
          renderContact += "<div style='margin: 0 0 10px 0;'><span style='font-size:14px;'>" + p.url + "</span>" +
          "<span style='height: 3px;width: 3px;background-color: #888888;border-radius: 50%;" +
          "display: inline-block;margin: 0 7px 3px 6px;'>" + "</span><span style='color:#888888;font-size:12px;'>" +
          p.url_type + "</span></div>";
        });
        renderContact += "</div>";
        renderContact += "</div>";
      }
      /* Events */
      if (c.events && c.events.length > 0) {
        renderContact += "<div style='display:flex;align-item: center;'>";
        renderContact += "<div style='width:35%;'>" + translations.EVENTS + "</div>";
        renderContact += "<div>";
        c.events.map(p => {
          renderContact += "<div style='margin: 0 0 10px 0;'><span style='font-size:14px;'>" + p.event_date + "</span>" +
          "<span style='height: 3px;width: 3px;background-color: #888888;border-radius: 50%;" +
          "display: inline-block;margin: 0 7px 3px 6px;'>" + "</span><span style='color:#888888;font-size:12px;'>" +
          p.event_type + "</span></div>";
        });
        renderContact += "</div>";
        renderContact += "</div>";
      }
      /* Timezone */
      if (c.timezone) {
        renderContact += "<div style='display:flex;align-item: center;'>";
        renderContact += "<div style='width:35%;'>" + translations.TIME_ZONE + "</div>";
        renderContact += "<div>";
        renderContact += "<div style='margin: 0 0 10px 0;'><span>" + c.timezone + "</span></div>";
        renderContact += "</div>";
        renderContact += "</div>";
      }
      /* Languge */
      if (c.language) {
        renderContact += "<div style='display:flex;align-item: center;'>";
        renderContact += "<div style='width:35%;'>" + translations.LANGUAGE_LABEL + "</div>";
        renderContact += "<div>";
        renderContact += "<div style='margin: 0 0 10px 0;'><span>" + c.language + "</span></div>";
        renderContact += "</div>";
        renderContact += "</div>";
      }
      /* Skills */
      if (c.skills && c.skills.length > 0) {
        renderContact += "<div style='display:flex;align-item: center;'>";
        renderContact += "<div style='width:35%;'>" + translations.SKILLS + "</div>";
        renderContact += "<div>";
        c.skills.map(s => {
          renderContact += "<div style='margin: 0 0 10px 0;'><span>" + s.name + "</span></div>";
        });
        renderContact += "</div>";
        renderContact += "</div>";
      }
      /* Interest */
      if (c.interests && c.interests.length > 0) {
        renderContact += "<div style='display:flex;align-item: center;'>";
        renderContact += "<div style='width:35%;'>" + translations.INTERESTS + "</div>";
        renderContact += "<div>";
        c.interests.map(s => {
          renderContact += "<div style='margin: 0 0 10px 0;'><span>" + s.name + "</span></div>";
        });
        renderContact += "</div>";
        renderContact += "</div>";
      }

       /* Tags */
       if (c.tags && c.tags.length > 0) {
        renderContact += "<div style='display:flex;align-item: center;'>";
        renderContact += "<div style='width:35%;'>" + translations.TAGS + "</div>";
        renderContact += "<div>";
        c.tags.map(s => {
          renderContact += "<div style='margin: 0 0 10px 0;'><span>" + s.name + "</span></div>";
        });
        renderContact += "</div>";
        renderContact += "</div>";
      }
      /* List */
      if (c.contact_list && c.contact_list.length > 0) {
        renderContact += "<div style='display:flex;align-item: center;'>";
        renderContact += "<div style='width:35%;'>" + translations.LISTS_LBL + "</div>";
        renderContact += "<div>";
        c.contact_list.map(s => {
          renderContact += "<div style='margin: 0 0 10px 0;'><span>" + s.name + "</span></div>";
        });
        renderContact += "</div>";
        renderContact += "</div>";
      }

      /* NOtes */
      if (c.notes) {
        renderContact += "<div style='display:flex;align-item: center;'>";
        renderContact += "<div style='width:35%;'>" + translations.NOTES + "</div>";
        renderContact += "<div>";
        renderContact += "<div style='margin: 0 0 10px 0;'><span>" + c.notes + "</span></div>";
        renderContact += "</div>";
        renderContact += "</div>";
      }

      /* HR Data */

      /* Birthday  */
      if (c.birthday) {
        renderContact += "<div style='display:flex;align-item: center;'>";
        renderContact += "<div style='width:35%;'>" + translations.DATE_OF_BIRTH + "</div>";
        renderContact += "<div>";
        renderContact += "<div style='margin: 0 0 10px 0;'><span>" + c.birthday + "</span></div>";
        renderContact += "</div>";
        renderContact += "</div>";
      }

      /* Gender  */
      if (c.birthday) {
        renderContact += "<div style='display:flex;align-item: center;'>";
        renderContact += "<div style='width:35%;'>" + translations.GENDER + "</div>";
        renderContact += "<div>";
        renderContact += "<div style='margin: 0 0 10px 0;'><span>" + c.gender + "</span></div>";
        renderContact += "</div>";
        renderContact += "</div>";
      }

       /* Marital status  */
       if (c.marital_status) {
        renderContact += "<div style='display:flex;align-item: center;'>";
        renderContact += "<div style='width:35%;'>" + translations.MARITAL_STATUS + "</div>";
        renderContact += "<div>";
        renderContact += "<div style='margin: 0 0 10px 0;'><span>" + c.marital_status + "</span></div>";
        renderContact += "</div>";
        renderContact += "</div>";
      }

       /* Private Email  */
       if (c.private_email) {
        renderContact += "<div style='display:flex;align-item: center;'>";
        renderContact += "<div style='width:35%;'>" + translations.PRIVATE_EMAIL + "</div>";
        renderContact += "<div>";
        renderContact += "<div style='margin: 0 0 10px 0;'><span>" + c.private_email + "</span></div>";
        renderContact += "</div>";
        renderContact += "</div>";
      }

      /* VNC Employee  */
      if (c.vnc_employee) {
        renderContact += "<div style='display:flex;align-item: center;'>";
        renderContact += "<div style='width:35%;'>" + translations.ENGAGEMENT_TYPE + "</div>";
        renderContact += "<div>";
        renderContact += "<div style='margin: 0 0 10px 0;'><span>" + (c.vnc_employee === "true" ? translations.VNC_EMPLOYEE : translations.NOT_VNC_EMPLOYEE) + "</span></div>";
        renderContact += "</div>";
        renderContact += "</div>";
      }

      /* Start Date  */
      if (c.start_date) {
        renderContact += "<div style='display:flex;align-item: center;'>";
        renderContact += "<div style='width:35%;'>" + translations.START_DATE + "</div>";
        renderContact += "<div>";
        renderContact += "<div style='margin: 0 0 10px 0;'><span>" + c.start_date + "</span></div>";
        renderContact += "</div>";
        renderContact += "</div>";
      }

      /* End Date  */
      if (c.end_date) {
        renderContact += "<div style='display:flex;align-item: center;'>";
        renderContact += "<div style='width:35%;'>" + translations.END_DATE + "</div>";
        renderContact += "<div>";
        renderContact += "<div style='margin: 0 0 10px 0;'><span>" + c.end_date + "</span></div>";
        renderContact += "</div>";
        renderContact += "</div>";
      }

       /* Week availability  */
       if (c.per_week_availability) {
        renderContact += "<div style='display:flex;align-item: center;'>";
        renderContact += "<div style='width:35%;'>" + translations.PER_WEEK_AVAILABLILITY + "</div>";
        renderContact += "<div>";
        renderContact += "<div style='margin: 0 0 10px 0;'><span>" + c.per_week_availability + "</span></div>";
        renderContact += "</div>";
        renderContact += "</div>";
      }

      /* Hourly rate  */
      if (c.hourly_rate) {
        renderContact += "<div style='display:flex;align-item: center;'>";
        renderContact += "<div style='width:35%;'>" + translations.HOURLY_RATE + "</div>";
        renderContact += "<div>";
        renderContact += "<div style='margin: 0 0 10px 0;'><span>" + c.hourly_rate + "</span></div>";
        renderContact += "</div>";
        renderContact += "</div>";
      }

      /* Payment Mode  */
      if (c.payment_mode) {
        renderContact += "<div style='display:flex;align-item: center;'>";
        renderContact += "<div style='width:35%;'>" + translations.PAYMENT_MODE + "</div>";
        renderContact += "<div>";
        renderContact += "<div style='margin: 0 0 10px 0;'><span>" + c.payment_mode + "</span></div>";
        renderContact += "</div>";
        renderContact += "</div>";
      }

      /* Events */
      if (c.im_accounts && c.im_accounts.length > 0) {
        renderContact += "<div style='display:flex;align-item: center;'>";
        renderContact += "<div style='width:35%;'>" + translations.IM_ADDRESS + "</div>";
        renderContact += "<div>";
        c.im_accounts.map(p => {
          renderContact += "<div style='margin: 0 0 10px 0;'><span style='font-size:14px;'>" + p.im_id + "</span>" +
          "<span style='height: 3px;width: 3px;background-color: #888888;border-radius: 50%;" +
          "display: inline-block;margin: 0 7px 3px 6px;'>" + "</span><span style='color:#888888;font-size:12px;'>" +
          p.im_type + "</span></div>";
        });
        renderContact += "</div>";
        renderContact += "</div>";
      }

      /* Passport number  */
      if (c.passport_number) {
        renderContact += "<div style='display:flex;align-item: center;'>";
        renderContact += "<div style='width:35%;'>" + translations.PASSPORT_NUMBER + "</div>";
        renderContact += "<div>";
        renderContact += "<div style='margin: 0 0 10px 0;'><span>" + c.passport_number + "</span></div>";
        renderContact += "</div>";
        renderContact += "</div>";
      }

      /* Passport expiry  */
      if (c.passport_expiry) {
        renderContact += "<div style='display:flex;align-item: center;'>";
        renderContact += "<div style='width:35%;'>" + translations.PASSPORT_EXPIRY + "</div>";
        renderContact += "<div>";
        renderContact += "<div style='margin: 0 0 10px 0;'><span>" + c.passport_expiry + "</span></div>";
        renderContact += "</div>";
        renderContact += "</div>";
      }

       /* National Id number  */
       if (c.national_id_number) {
        renderContact += "<div style='display:flex;align-item: center;'>";
        renderContact += "<div style='width:35%;'>" + translations.NATIONAL_ID_NUMBER + "</div>";
        renderContact += "<div>";
        renderContact += "<div style='margin: 0 0 10px 0;'><span>" + c.national_id_number + "</span></div>";
        renderContact += "</div>";
        renderContact += "</div>";
      }

      /* National Id expiry  */
      if (c.national_id_expiry) {
        renderContact += "<div style='display:flex;align-item: center;'>";
        renderContact += "<div style='width:35%;'>" + translations.NATIONAL_ID_EXPIRY + "</div>";
        renderContact += "<div>";
        renderContact += "<div style='margin: 0 0 10px 0;'><span>" + c.national_id_expiry + "</span></div>";
        renderContact += "</div>";
        renderContact += "</div>";
      }

      /* Account Details */

      /* User name  */
      if (c.username) {
        renderContact += "<div style='display:flex;align-item: center;'>";
        renderContact += "<div style='width:35%;'>" + translations.USER_NAME + "</div>";
        renderContact += "<div>";
        renderContact += "<div style='margin: 0 0 10px 0;'><span>" + c.username + "</span></div>";
        renderContact += "</div>";
        renderContact += "</div>";
      }

       /* is admin */
       if (c.admin) {
        renderContact += "<div style='display:flex;align-item: center;'>";
        renderContact += "<div style='width:35%;'>" + translations.ROLE_LBL + "</div>";
        renderContact += "<div>";
        renderContact += "<div style='margin: 0 0 10px 0;'><span>" +
        (c.admin === "true" ? translations.ADMIN : translations.USER) + "</span></div>";
        renderContact += "</div>";
        renderContact += "</div>";
      }

      /* Agb Accepted  */
      if (c.agb_accepted) {
        renderContact += "<div style='display:flex;align-item: center;'>";
        renderContact += "<div style='width:35%;'>" + translations.ACCEPTED_TERMS + "</div>";
        renderContact += "<div>";
        renderContact += "<div style='margin: 0 0 10px 0;'><span>" +
        (c.agb_accepted === "true" ? translations.AGB_FLAG : translations.NOT_AGB_FLAG) + "</span></div>";
        renderContact += "</div>";
        renderContact += "</div>";
      }

      /* RFC limit  */
      if (c.rfc_limit) {
        renderContact += "<div style='display:flex;align-item: center;'>";
        renderContact += "<div style='width:35%;'>" + translations.RFC_LIMIT + "</div>";
        renderContact += "<div>";
        renderContact += "<div style='margin: 0 0 10px 0;'><span>" + c.rfc_limit + "</span></div>";
        renderContact += "</div>";
        renderContact += "</div>";
      }

      /* Video bridge  */
      if (c.video_bridge) {
        renderContact += "<div style='display:flex;align-item: center;'>";
        renderContact += "<div style='width:35%;'>" + translations.VIDEO_BRIDGE + "</div>";
        renderContact += "<div>";
        renderContact += "<div style='margin: 0 0 10px 0;'><span>" +
        (c.video_bridge === "true" ? translations.ALLOW : translations.NOT_ALLOW ) + "</span></div>";
        renderContact += "</div>";
        renderContact += "</div>";
      }

      /* Omemo  */
      if (c.omemo) {
        renderContact += "<div style='display:flex;align-item: center;'>";
        renderContact += "<div style='width:35%;'>" + translations.SECURITY_ENCRYPTION + "</div>";
        renderContact += "<div>";
        renderContact += "<div style='margin: 0 0 10px 0;'><span>" +
        (c.omemo === "true" ? translations.OMEMO : translations.NOT_OMEMO) + "</span></div>";
        renderContact += "</div>";
        renderContact += "</div>";
      }

      /* Interest */
      if (c.products && c.products.length > 0) {
        renderContact += "<div style='display:flex;align-item: center;'>";
        renderContact += "<div style='width:35%;'>" + translations.PRODUCTS + "</div>";
        renderContact += "<div>";
        c.products.map(s => {
          renderContact += "<div style='margin: 0 0 10px 0;'><span>" + s.name + "</span></div>";
        });
        renderContact += "</div>";
        renderContact += "</div>";
      }

      renderContact += "<hr/>";
    });
    console.log("[printContacts]:" + renderContact);
    if (environment.isCordova) {
      console.log("[cordova][printContacts]:" + renderContact);
      cordova.plugins.printer.print(renderContact, { duplex: "long" }, function (response) {
      });
    } else {
      const windowPrint = window.open("", "", "left=0,top=0,width=900,height=700,toolbar=0,scrollbars=0,status=0");
      windowPrint.document.write(renderContact);
      windowPrint.document.close();
      windowPrint.focus();
      windowPrint.print();
    }
  }

  public loadMoreAdvanceSearchContacts(query?: string) {
    let nextPageLoading: boolean;
    let offset = 0;
    let isMoreContacts = false;
    this.store.select(getContactsCurrentPageOffset).pipe(take(1)).subscribe(o => offset = o);
    this.store.select(getIsMoreContacts).pipe(take(1)).subscribe(l => isMoreContacts = l);
    if (nextPageLoading) {
      return;
    }
    if (!isMoreContacts) {
      return;
    }
    this.store.dispatch(new NextLoadContacts());
    this.contactService.getSearchFromAdvanceSearchQuery(offset, this.limit, query).subscribe(res => {
      if (!!res && res !== null && res.contacts) {
        let contacts: Contact[] = [];
        contacts = this.mapContacts(res.contacts);
        let newOffset = offset + contacts.length;
        if (contacts.length === this.limit) {
          isMoreContacts = true;
        }
        else {
          newOffset = 0;
          isMoreContacts = false;
        }
        this.store.dispatch(new NextLoadContactsSuccess({
          currOffset: newOffset,
          isMoreContacts: isMoreContacts,
          contacts: contacts as Contact[]
        }));
      }
    }, err => {
      if (err === undefined) {
        this.store.dispatch(new NextLoadContactsFail());
        this.toastService.show("NETWORK_CONNECTION_LOST");
        return;
      }
      this.store.dispatch(new NextLoadContactsFail());
      this.toastService.showPlainMessage(err);
    });
  }


  shareContactViaEmail(contactIds: string[], emails: string) {
    const body = {
      "ids": contactIds,
      "emails": emails
    };
    this.contactService.shareContactViaEmail(body).pipe(take(1)).subscribe( res => {
      this.toastService.show("SHARE_CONTACT_VIA_EMAIL_SUCCESS");
    }, error => {
      console.log("[Error]: ", error);
      this.toastService.showPlainMessage(error);
    });
  }

  getAllFrequntlyContacts(): void {
    setTimeout(() => {
      this.store.dispatch(new LoadFrequntlyContacts());
    }, 0);
    let offset = 0;
    this.contactService.getFrequentlyContacts(offset, this.limit).pipe(take(1)).subscribe(res => {
      if (!!res && res !== null && res.contacts) {
        let contacts: Contact[] = [];
        contacts = this.mapContacts(res.contacts);
        let isMoreContacts = false;
        offset = contacts.length;
        if (contacts.length === this.limit) {
          isMoreContacts = true;
        } else {
          offset = 0;
          isMoreContacts = false;
        }
        this.store.dispatch(new LoadFrequntlyContactsSuccess({ currOffset: offset, isMoreContacts: isMoreContacts, contact: contacts }));
      } else {
        this.store.dispatch(new LoadFrequntlyContactsFail());
      }
    }, error => {
      this.store.dispatch(new LoadFrequntlyContactsFail());
      this.toastService.showPlainMessage(error);
    });
  }

  loadMoreFrequentlyContacts(): void {
    let nextPageLoading: boolean;
    let offset = 0;
    let isMoreContacts = false;
    this.store.select(getFrequentlyContactsCurrentPageOffset).pipe(take(1)).subscribe(o => offset = o);
    this.store.select(getIsMoreFrequentlyContacts).pipe(take(1)).subscribe(l => isMoreContacts = l);
    if (nextPageLoading) {
      return;
    }
    if (!isMoreContacts) {
      return;
    }
    this.store.dispatch(new NextLoadTrashContacts());
    this.contactService.getFrequentlyContacts(offset, this.limit).subscribe(res => {
      if (!!res && res !== null && res.contacts) {
        let contacts: Contact[] = [];
        contacts = this.mapContacts(res.contacts);
        let newOffset = offset + contacts.length;
        if (contacts.length === this.limit) {
          isMoreContacts = true;
        }
        else {
          newOffset = 0;
          isMoreContacts = false;
        }
        this.store.dispatch(new NextLoadFrequntlyContactsSuccess({
          currOffset: newOffset,
          isMoreContacts: isMoreContacts,
          contacts: contacts as Contact[]
        }));
      }
    }, err => {
      if (err === undefined) {
        this.store.dispatch(new NextLoadFrequntlyContactsFail());
        this.toastService.show("NETWORK_CONNECTION_LOST");
        return;
      }
      this.store.dispatch(new NextLoadFrequntlyContactsFail());
      this.toastService.showPlainMessage(err);
    });
  }

  makeBroadcastOperation(contact: Contact[]): void {
    const emails: any[] = [];
    contact.map( c => {
      if (c.jid && c.jid !== null && c.jid !== "") {
        emails.push(c.jid);
      } else if (!!c.emails && c.emails !== null && c.emails.length > 0) {
        emails.push(c.emails[0].email);
      }
    });
    if (emails.length > 10) {
      this.toastService.show("SEND_EMAIL_LIMIT_ERROR_MSG");
    } else if (emails.length === 0) {
      this.toastService.show("CONTACT_MUST_HAVE_EMAIL_ADDRESS");
    } else {
        const dialog = this.matDialog.open(CreateActionGroupDialogComponent, {
          width: "325px",
          height: "212px",
          autoFocus: true,
          panelClass: "create-tag-dialog"
        });
        dialog.afterClosed().pipe(take(1)).subscribe(res => {
          if (!!res && res.group && res.group !== "") {
            const broadcastTitle = res.group;
            this.broadcasTriggerAction("broadcast", emails.toString(), broadcastTitle);
          }
        });
    }
  }

  broadcasTriggerAction(action: string, target: string, broadcastTitle?: string) {
    let talkAppURL: string = "";
    this.store.select(getFederatedApps).pipe(take(1)).subscribe(apps => {
      talkAppURL = apps.filter( ap => ap.name === "vnctalk")[0].options.url;
    });
    talkAppURL = this.getURL(talkAppURL);
    let sendURL = talkAppURL + "talk/trigger?action=" + action + "&target=" + target;
    if (broadcastTitle) {
      sendURL += "&broadcastTitle=" + broadcastTitle;
    }
    if (environment.isCordova) {
      if (device.platform === "iOS") {
        window.open(sendURL, "_system");
      } else if (device.platform === "Android") {
        navigator.app.loadUrl(sendURL, {
          openExternal: true
        });
      }
    } else if (environment.isElectron) {
      this.electronService.openExternalUrl(sendURL);
    } else {
      window.open(sendURL, "_blank");
    }
  }

  composeCalendarEvent(contact: Contact[]): void {
    const emails: any[] = [];
    contact.map( c => {
      if (!!c.emails && c.emails !== null && c.emails.length > 0) {
        emails.push(c.emails[0].email);
      }
    });
    if (emails.length > 10) {
      this.toastService.show("SEND_EMAIL_LIMIT_ERROR_MSG");
    } else if (emails.length === 0) {
      this.toastService.show("CONTACT_MUST_HAVE_EMAIL_ADDRESS");
    } else {
      let calendarAppURL: string = "";
      this.store.select(getFederatedApps).pipe(take(1)).subscribe(apps => {
        calendarAppURL = apps.filter( ap => ap.name === "vnccalendar")[0].options.url;
      });
      calendarAppURL = this.getURL(calendarAppURL);
      if (calendarAppURL.indexOf("/calendar/") !== -1) {
        calendarAppURL = calendarAppURL.replace("/calendar/", "/");
      }
      const sendURL = calendarAppURL + "calendar/cal-ev-create?to=" + emails.toString();
      const sendWebURL = calendarAppURL + "api/deeplink/calendar/cal-ev-create?to=" + emails.toString();
      if (environment.isCordova) {
        const nativeLink2 = "vncmail://" + sendURL.split("calendar/")[1];
        this.contactService.checkInstalledApp("vncmail://", "vncmail").pipe(take(1)).subscribe(installed => {
          console.log("composeCalendarEvent checkinstalled: ", installed);
          if (installed) {
            if (device.platform === "iOS") {
              window.open(nativeLink2, "_system");
            } else if (device.platform === "Android") {
              navigator.app.loadUrl(nativeLink2, {
                openExternal: true
              });
            }
          } else {
            const url = CommonUtil.getAppStoreOrWeblink({ name: "vncmail" });
            if (device.platform === "iOS") {
              window.open(url, "_system");
            } else if (device.platform === "Android") {
              navigator.app.loadUrl(url, {
                openExternal: true
              });
            }
          }
        });

      } else if (environment.isElectron) {
        try {
          let nativeHandlerCal = this.electronService.app.getApplicationNameForProtocol("vnccalendar://");
          let nativeHandler;
          let nativeLink;
          if (nativeHandlerCal && nativeHandlerCal !== "") {
            nativeHandler = nativeHandlerCal;
            nativeLink = "vnccalendar://" + sendURL.split("calendar/")[1];
          } else {
            nativeHandler = this.electronService.app.getApplicationNameForProtocol("vncmail://");
            nativeLink = "vncmail://" + sendURL.split("calendar/")[1];
          }
          console.log("[contacts.repo] nativeHandler: ", nativeHandler);
          if (nativeHandler && nativeHandler !== "") {

            this.electronService.openExternalUrl(nativeLink);
          } else {
            this.electronService.openExternalUrl(CommonUtil.translateAppURLtoDeeplinkURL(sendURL));
          }
        } catch (e) {
          console.error("[contactRepository] error: ", e);
        }
      } else {
        window.open(sendWebURL, "_blank");
      }
    }
  }

  importCSVContactFromBlob(blob: any, mapping: string, selectedList, importAddress: any, importEventsDates: any = {}, importNotes: any, importURLs: any, customFields: any, importIMData:any): void {
    console.log("[importCSVContactFromBlob]", blob);
    this.store.dispatch(new StartLoading());
    this.contactService.importCSVContactFromBol(blob, mapping).pipe(take(1)).subscribe(res => {
    res.contacts.map((contacts, index)=>{
      this.submit(contacts,selectedList,importAddress[index], importEventsDates[index], importNotes[index], importURLs[index], customFields, importIMData[index]);
    });
      this.store.dispatch(new StopLoading());
      if (!!res && res !== null && res.contacts) {
        let contacts: Contact[] = [];
        contacts = this.mapContacts(res.contacts);
        const routeId = this.getCurrentRouteId();
        if (routeId === AppConstants.CONTACTS_ALL) {
          contacts.map(c => {
            this.store.dispatch(new CreateContactSuccess(c));
          });
        }
      }
      this.broadcaster.broadcast(BroadcastKeys.HIDE_IMPORT_CONTACT_DIALOG);
      this.toastService.show("CONTACT_IMPORT_SUCCESS_MSG");
      this.getStatistics();
    }, error => {
      console.log("[Error]: ", error);
      this.store.dispatch(new StopLoading());
      this.toastService.showPlainMessage(error);
    });
  }


  resetContactDetail() {
    this.contactDetails = {
      first_name: "",
      last_name: "",
      middle_name: "",
      job_title: "",
      is_company: "0",
      emails_attributes: [],
      phones_attributes: [],
      addresses_attributes: [],
      urls_attributes: [],
      im_accounts_attributes: [],
      company: "",
      firstLastCharacters: "",
      notes: "",
      custom_fields_attributes: [],
      events_attributes: [],
      contact_list_ids: [],
      tag_list: "",
      bgAvatarColor: "",
      favorite: false
    };
  }
  submit(contactDetails, selectedLists,importAddress, eventdates: any, importNotes: any, importURLs: any,customFields : any , importIMData :any = {}): void {
    this.resetContactDetail();
    this.contactDetails = ContactUtils.initializeEditContactDetailApiDataState(contactDetails, this.contactDetails);
    if (this.contactDetails.first_name === "" || this.contactDetails.first_name === undefined) {
        this.toastService.show("FIRST_NAME_REQUIRED");
        return;
    }
    let isValidEmail: boolean = true;
    if (this.contactDetails.emails_attributes.length > 0) {
        this.contactDetails.emails_attributes.map(item => {
            if (item.email !== "" && !ContactUtils.validateMail(item.email)) {
                isValidEmail = false;
            }
        });
    }
    if (!isValidEmail) {
        this.toastService.show("ENTER_VALID_EMAIL_MSG");
        return;
    }
    this.contactDetails.is_company = "0";

    if (selectedLists.length > 0) {
        this.contactDetails.contact_list_ids = selectedLists.map( l => l.id);
    }
    const contactDetailItem = _.clone(this.contactDetails, true);
    contactDetailItem.addresses_attributes = [];
    if (importAddress.importHomeAddress.length) {
      importAddress.importHomeAddress.map(homeAdr=>{
        let data = {
          address_type: "home",
          street1: homeAdr[0],
          city: homeAdr[1],
          state: homeAdr[2],
          postcode: homeAdr[3],
          country_code: homeAdr[4],
        };
        contactDetailItem.addresses_attributes.push(data);
      });

    }

    if (importAddress.importWorkAddress.length) {
      importAddress.importWorkAddress.map(workAdr=>{
        let data = {
          address_type: "work",
          street1: workAdr[0],
          city: workAdr[1],
          state: workAdr[2],
          postcode: workAdr[3],
          country_code: workAdr[4],
        };
        contactDetailItem.addresses_attributes.push(data);
      });
    }

    if (importAddress.importOtherAddress.length) {
      importAddress.importOtherAddress.map(otherAdr=>{
        let data = {
          address_type: "other",
          street1: otherAdr[0],
          city: otherAdr[1],
          state: otherAdr[2],
          postcode: otherAdr[3],
          country_code: otherAdr[4],
        };
        contactDetailItem.addresses_attributes.push(data);
      });

    }

    if (importIMData.xmpp.length) {
      importIMData.xmpp.map(xmpp=>{
        let data = {
          im_type: "XMPP",
          im_id: xmpp,
        };
        contactDetailItem.im_accounts_attributes.push(data);
      });
    }

    if (importIMData.mastodon.length) {
      importIMData.mastodon.map(mastodon=>{
        let data = {
          im_type: "mastodon",
          im_id: mastodon,
        };
        contactDetailItem.im_accounts_attributes.push(data);
      });
    }

    if (importIMData.twitter.length) {
      importIMData.twitter.map(twitter=>{
        let data = {
          im_type: "twitter",
          im_id: twitter,
        };
        contactDetailItem.im_accounts_attributes.push(data);
      });
    }

    if (importIMData.others.length) {
      importIMData.others.map(other=>{
        let data = {
          im_type: "others",
          im_id: other,
        };
        contactDetailItem.im_accounts_attributes.push(data);
      });
    }

    if (eventdates) {
      if (eventdates.importBirthDay && eventdates.importBirthDay.length) {
        eventdates.importBirthDay.map(bday=>{
          let data = {
            event_type: "birthday",
            event_date: bday,
          };
          contactDetailItem.events_attributes.push(data);
        });
      }

      if (eventdates.importAnniversary && eventdates.importAnniversary.length) {
        eventdates.importAnniversary.map(anniversary=>{
          let data = {
            event_type: "anniversary",
            event_date: anniversary,
          };
          contactDetailItem.events_attributes.push(data);
        });
      }

      if (eventdates.importCustom && eventdates.importCustom.length) {
        eventdates.importCustom.map(custom=>{
          let data = {
            event_type: "custom",
            event_date: custom,
          };
          contactDetailItem.events_attributes.push(data);
        });
      }

    }

    if (importURLs) {
      if (importURLs.home && importURLs.home.length) {
        importURLs.home.map(home=>{
          let data = {
            url_type: "home",
            url: home,
          };
          contactDetailItem.urls_attributes.push(data);
        });
      }

      if (importURLs.work && importURLs.work.length) {
        importURLs.work.map(work=>{
          let data = {
            url_type: "work",
            url: work,
          };
          contactDetailItem.urls_attributes.push(data);
        });
      }

      if (importURLs.others && importURLs.others.length) {
        importURLs.others.map(others=>{
          let data = {
            url_type: "others",
            url: others,
          };
          contactDetailItem.urls_attributes.push(data);
        });
      }

    }

    if (customFields) {
      if (customFields && customFields.length) {
        customFields.map(cF=>{
          let data = {
            field_name: cF.field_name,
            field_value: cF.field_value,
          };
          contactDetailItem.custom_fields_attributes.push(data);
        });
      }

    }
    if (importNotes){
      contactDetailItem.notes = importNotes;
    }
    this.updateContact(contactDetailItem, contactDetailItem.favorite);
}

  undoChangeContact(sentDate: any): void {
    this.contactService.undoChangesContact(sentDate).pipe(take(1)).subscribe(res => {
      console.log("[undoChangeContacct][res]: ", res);
      if (!!res && res.success === true) {
        this.broadcaster.broadcast(BroadcastKeys.HIDE_UNDO_CHANGES_DIALOG);
        this.translate.get("CONTACT_LIST_RESTORE", { dateItem: sentDate }).pipe(take(1)).subscribe((text: string) => {
          this.toastService.showPlainMessage(text);
        });
        const routingType = this.getCurrentRouteType();
        const routingId = this.getCurrentRouteType();
        if (routingType === AppConstants.CONTACTS) {
          this.getAllContact();
        } else if (routingType === AppConstants.LIST) {
          this.getContactFromList(routingId);
        } else if (routingType === AppConstants.TAG) {
          this.getContactFromTag(routingId);
        }
      }
    }, error => {
      console.log("[Error]", error);
    });
  }

  openUrlNewTab(url: string): void {
    console.log("[openUrlNewTab]: ", url);
    if (environment.isCordova) {
        window.open(url, "_system");
    } else if (environment.isElectron) {
        this.electronService.openExternalUrl(url);
    } else {
        window.open(url, "_blank");
    }
  }

  sendMailToEmail(email: string): void {
    let mailAppURL: string = "";
    this.store.select(getFederatedApps).pipe(take(1)).subscribe(apps => {
      mailAppURL = apps.filter( ap => ap.name === "vncmail")[0].options.url;
    });
    mailAppURL = this.getURL(mailAppURL);
    const sendURL = mailAppURL + "mail/compose?to=" + email;
    if (environment.isCordova) {
      const nativeURL = "vncmail://compose?to=" + email;
      console.log("USING NATIVE URL: ", nativeURL);
      if (device.platform === "iOS") {
        window.open(nativeURL, "_system");
      } else if (device.platform === "Android") {
        navigator.app.loadUrl(nativeURL, {
          openExternal: true
        });
      }
    } else if (environment.isElectron) {
      this.electronService.openExternalUrl(sendURL);
    } else {
      window.open(sendURL, "_blank");
    }
  }

  fetchContactIds(offset:number): Observable<number[]> {
    const response = new Subject<any>();
    let contacts: Contact[] = [];
    this.contactService.getAllDirectoryContact(offset, 500, null,false,this.sortColumn,this.sortOrder).pipe(take(1)).subscribe(res => {
      if (!!res && res !== null && res.contacts) {
        contacts = this.mapContacts(res.contacts);
        const contactids = contacts.map(c => c.id);
        response.next(contactids);
      }
    });
    return response.asObservable().pipe(take(1));
  }

  fetchAggregatedContactIds(offset: number) {
    if (offset === 0) {
      this.allContactIds = [];
    }
    this.fetchContactIds(offset).pipe(take(1)).subscribe(ids => {
      if (!!ids && (ids.length > 0)) {
        this.allContactIds = this.allContactIds.concat(ids);
        if (ids.length === 500) {
          this.fetchAggregatedContactIds(offset + 500);
        } else {
          this.fetchedContacts.next(true);
        }
      }
    });
  }

  fetchGlobalContactIds(offset: number): Observable<number[]> {
    const response = new Subject<any>();
    let contacts: Contact[] = [];
    this.contactService.getAllDirectoryContact(offset, 500, null, true,this.sortOrder).pipe(take(1)).subscribe(res => {
      if (!!res && res !== null && res.contacts) {
        contacts = this.mapContacts(res.contacts);
        const contactids = contacts.map(c => c.id);
        response.next(contactids);
      }
    });
    return response.asObservable().pipe(take(1));
  }

  fetchAggregatedGlobalContactIds(offset: number) {
    if (offset === 0) {
      this.allGlobalContactIds = [];
    }
    this.fetchGlobalContactIds(offset).pipe(take(1)).subscribe(ids => {
      if (!!ids && (ids.length > 0)) {
        this.allGlobalContactIds = this.allGlobalContactIds.concat(ids);
        if (ids.length === 500) {
          this.fetchAggregatedGlobalContactIds(offset + 500);
        } else {
          this.fetchedGlobalContacts.next(true);
        }
      }
    });
  }

  fetchTrashContactIds(offset: number): Observable<number[]> {
    const response = new Subject<any>();
    let contacts: Contact[] = [];
    this.contactService.getTrashContacts(offset, 500).pipe(take(1)).subscribe(res => {
      if (!!res && res !== null && res.contacts) {
        contacts = this.mapContacts(res.contacts);
        const contactids = contacts.map(c => c.id);
        response.next(contactids);
      }
    });
    return response.asObservable().pipe(take(1));
  }

  fetchAggregatedTrashContactIds(offset: number) {
    if (offset === 0) {
      this.allTrashContactIds = [];
    }
    this.fetchTrashContactIds(offset).pipe(take(1)).subscribe(ids => {
      if (!!ids && (ids.length > 0)) {
        this.allTrashContactIds = this.allTrashContactIds.concat(ids);
        if (ids.length === 500) {
          this.fetchAggregatedTrashContactIds(offset + 500);
        } else {
          this.fetchedTrashContacts.next(true);
        }
      }
    });
  }


  resyncData(): void {
    console.log("contact.repo starting resync");
    this.fetchAggregatedContactIds(0);
  }

getSelectedContactName () {
return this.store.select(getContacts);

}

getContactNameByID(email): Promise<string> {
    const indexDBservice = new IndexedDBService();
    return this.customLastValueFrom(indexDBservice.fetchContactsByEmail(email));
}

customLastValueFrom(observable$):any {
  return new Promise((resolve, reject) => {
    let lastValue;

    observable$.subscribe({
      next(value) {
        lastValue = value;
      },
      error(error) {
        reject(error);
      },
      complete() {
        resolve(lastValue);
      }
    });
  });
}

getAllContactFromDBById(email) : Promise<string>{
  return new Promise((resolve, reject) =>{
    let name = "";
    if (this.databaseService.dbReady.value) {
      this.databaseService.fetchContacts(true).subscribe(result => {
        const foundObj = result.find(obj => obj.email === email);
          if (foundObj) {
            name = foundObj.first_name;
            if (foundObj && foundObj.middle_name) {name = name + " " + foundObj.middle_name;}
            if (foundObj && foundObj.last_name) {name = name + " " + foundObj.last_name;}
          }
      });

      if (!name) {
        this.databaseService.fetchContacts(false).subscribe(result => {
          const foundObj = result.find(obj => obj.email === email);
            if (foundObj) {
              name = foundObj.first_name;
              if (foundObj && foundObj.middle_name) {name = name + " " + foundObj.middle_name;}
              if (foundObj && foundObj.last_name) {name = name + " " + foundObj.last_name;}
            }
        });
      }
      setTimeout(() => {
        resolve(name);
      }, 500);
    }
  });
}

getContactFromListCount(id: string, query = ""):  Observable<any> {
  const loadContacts$ = new Observable<void>((observer) => {
    setTimeout(() => {
      observer.next();
      observer.complete();
    }, 0);
  });
  const onlineContacts$ = this.contactService.getContactListContact(id, query).pipe(
    take(1),
    mergeMap((res) => {
      let contacts: Contact[] = [];
      let offset = 0;
      let isMoreContacts = false;

      if (!!res && res !== null && res.contacts) {
        contacts = this.mapContacts(res.contacts);
        offset = contacts.length;
        isMoreContacts = contacts.length === this.limit;
      }
      return of(contacts);
    }),
    catchError((error) => {
      return of([]);
    })
  );

  const offlineContacts$ = this.databaseService.getContactsByList(id).pipe(
    take(1),
    mergeMap((res) => {
      const contacts: Contact[] = this.mapContacts(res);
      return of(contacts);
    }),
    catchError((error) => {
      return of([]);
    })
  );

  const online$ = of(this.isOnline).pipe(mergeMap((isOnline) => (isOnline ? onlineContacts$ : offlineContacts$)));

  return loadContacts$.pipe(mergeMap(() => online$));
}

getContactFromTagCount(id: string, query?: string):  Observable<any> {
  const loadContacts$ = new Observable<void>((observer) => {
    setTimeout(() => {
      observer.next();
      observer.complete();
    }, 0);
  });
  const onlineContacts$ = this.contactService.getTagContact(id, query).pipe(
    take(1),
    mergeMap((res) => {
      let contacts: Contact[] = [];
      let offset = 0;
      let isMoreContacts = false;

      if (!!res && res !== null && res.contacts) {
        contacts = this.mapContacts(res.contacts);
        offset = contacts.length;
        isMoreContacts = contacts.length === this.limit;
      }
      return of(contacts);
    }),
    catchError((error) => {
      return of([]);
    })
  );
  const online$ = of(this.isOnline).pipe(mergeMap((isOnline) => (isOnline ? onlineContacts$ : null)));

  return loadContacts$.pipe(mergeMap(() => online$));
}

  deleteContactFromDB(id) {
    this.store.dispatch(new RemoveMultipleContactSuccess([id]));
    return this.databaseService.deleteContacts([id]);
  }

  createContactsInDB(contacts) {
    contacts.forEach(c => {
      const newContact = this.mapContacts([c]);
      // console.log("UpdateContactSuccess: ", { id: newContact[0].id, changes: newContact[0] });
      setTimeout(() => {
        this.store.dispatch(new CreateContactSuccess(newContact[0]));
        setTimeout(() => {
          this.store.dispatch(new StopLoading());
        }, 50);
      }, 100);
    });
    return this.databaseService.createOrUpdateContacts(contacts);
  }

  updateContactsInDB(contacts) {
    contacts.forEach(c => {
      const newContact = this.mapContacts([c]);
      console.log("UpdateContactSuccess: ", { id: newContact[0].id, changes: newContact[0] });
      setTimeout(() => {
        this.store.dispatch(new UpdateContactSuccess({ id: newContact[0].id, changes: newContact[0] }));
        setTimeout(() => {
          this.store.dispatch(new StopLoading());
        }, 50);
      }, 100);
    });
    return this.databaseService.createOrUpdateContacts(contacts);
  }

  mapContactRequestToDBcontact(requestbody, isGlobal = false): Observable<any> {
    const response = new Subject<any>();

    console.log("mapContactRequestToDBcontact requestbody: ", requestbody);
    let dbContact = { ...requestbody };
    if (!!requestbody.addresses_attributes && (requestbody.addresses_attributes.length > 0)) {
      delete dbContact.addresses_attributes;
      dbContact["addresses"] = requestbody.addresses_attributes;
    }
    if (!!requestbody.emails_attributes && (requestbody.emails_attributes.length > 0)) {
      delete dbContact.emails_attributes;
      dbContact["emails"] = requestbody.emails_attributes;
    }
    if (!!requestbody.events_attributes && (requestbody.events_attributes.length > 0)) {
      delete dbContact.events_attributes;
      dbContact["events"] = requestbody.events_attributes;
    }
    if (!!requestbody.im_accounts_attributes && (requestbody.im_accounts_attributes.length > 0)) {
      delete dbContact.im_accounts_attributes;
      dbContact["im_accounts"] = requestbody.im_accounts_attributes;
    }
    if (!!requestbody.phones_attributes && (requestbody.phones_attributes.length > 0)) {
      delete dbContact.phones_attributes;
      dbContact["phones"] = requestbody.phones_attributes;
    }
    if (!!requestbody.urls_attributes && (requestbody.urls_attributes.length > 0)) {
      delete dbContact.urls_attributes;
      dbContact["urls"] = requestbody.urls_attributes;
    }
    dbContact.is_company = (requestbody.is_company === "1");
    dbContact.is_global = !!isGlobal ? "true" : "false";


    this.databaseService.getContactLists().subscribe(clists => {
      if (!!requestbody.contact_list_ids && (requestbody.contact_list_ids.length > 0)) {
        const contact_lists = clists.filter(l => (requestbody.contact_list_ids.indexOf(l.id) > -1));
        dbContact.contact_lists = contact_lists;
      }
      console.log("FILTERDEBUG: ", requestbody.contact_list_ids, dbContact);
      response.next(dbContact);
    });
    return response.asObservable().pipe(take(1));
  }

  processPendingOperations() {
    if (!this.isProcessingPending) {
      this.isProcessingPending = true;
      this.databaseService.getAllPendingOperations().subscribe(ops => {
        console.log("processPendingOps: ", ops);
        // deleteOps - filter out pending created contacts (id < 0) and process, then clean up stored pending ops
        const deleteOps = ops.filter(o => o.type === "deleteContact");
        const bulkDeleteOpIds = deleteOps.map(p => p.id).filter(o => o > 0);

        if (bulkDeleteOpIds.length > 0) {
          console.log("processPendingOps DELETE: ", bulkDeleteOpIds);
          this.contactService.deleteBulkContact(bulkDeleteOpIds).pipe(take(1)).subscribe(res => {
            if (!!res && res.success) {
              if (deleteOps.length > 0) {
                for (let i = 0; i < deleteOps.length; i++) {
                  this.databaseService.deletePendingOpById(deleteOps[i].opid).subscribe();
                  const nts = Date.now();
                  this.triggerAfterProcessPendings$.next(nts);
                }
              }
            }
          });
        }

        const updateOps = ops.filter(o => o.type === "updateContact");
        if (updateOps.length > 0) {
          console.log("processPendingOps UPDATE: ", updateOps);
          for (let i = 0; i < updateOps.length; i++) {
            const requestbody = updateOps[i].operation.contact.contact;
            this.contactService.updateContact(requestbody).pipe(take(1)).subscribe(res => {
              if (!!res && res.contact) {
                this.databaseService.deletePendingOpById(updateOps[i].opid).subscribe();
                const nts = Date.now();
                this.triggerAfterProcessPendings$.next(nts);
              }
            });
          }

        }

        const createOps = ops.filter(o => o.type === "createContact");
        if (createOps.length > 0) {
          console.log("processPendingOps UPDATE: ", createOps);
          for (let i = 0; i < createOps.length; i++) {
            const requestbody = createOps[i].operation.contact.contact;
            this.contactService.createContact(requestbody).pipe(take(1)).subscribe(res => {
              if (!!res && res.contact) {
                this.databaseService.deletePendingOpById(createOps[i].opid).subscribe();
                const nts = Date.now();
                this.triggerAfterProcessPendings$.next(nts);
              }
            });
          }
        }
        setTimeout(() => {
          this.isProcessingPending = false;
        }, 2000);
      });
    }
  }

  updateAvatarCache() {
    if (!!this.configService.worker) {
      this.configService.worker.postMessage({ type: "updateAvatarCache", id: new Date().getTime() });
    }
  }

  getCachedAvatar(email) {
    // console.log("getCachedAvatar: ", email, this.cachedAvatars);
    if (this.cachedAvatars.length > 0) {
      const filteredCachedAvatars = this.cachedAvatars.filter(a => a.id === email);
      if (!!filteredCachedAvatars && filteredCachedAvatars.length > 0) {
        return filteredCachedAvatars[0].data;
      } else {
        return "";
      }
    } else {
      return "";
    }
  }

  updateCachedAvatars(data) {
    this.databaseService.fetchAllAvatarFromDatabase().pipe(take(1)).subscribe(dbAvatars => {
      if (!!dbAvatars && (dbAvatars.length > 0)) {
        this.cachedAvatars = [...dbAvatars];
      }
      // console.log("cachedAvatartsUpdate: ", data, this.cachedAvatars);
      this.broadcaster.broadcast("CACHED_AVATARS_UPDATED", data);
    });

  }
}
